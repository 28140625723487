import React, { useState, useContext } from 'react';
import {
  UserContext,
  UserContextType,
  isStaffType,
} from '../../context/UserContext';
import { isWindowDefined } from '../../utils/browserHelper';

import { StaticImage } from 'gatsby-plugin-image';
import { FaUser, FaExternalLinkAlt } from 'react-icons/fa';
import agent from '../../agent';
import UploadPhotoModal from './UploadPhotoModal';

const ProfilePicture = (props: {
  photoUrl?: string;
  alt: string;
  isThirdParty?: boolean;
  className?: string;
  allowEdit?: boolean;
  resumePreview?: boolean;
  squarePhoto?: boolean;
}) => {
  const { photoUrl, alt, isThirdParty, className, allowEdit, resumePreview } =
    props;
  const { user, updateUser } = useContext(UserContext) as UserContextType;

  const [errorMessage, setErrorMessage] = useState('');
  const imageStyle = props.squarePhoto
    ? undefined
    : { borderRadius: '50%', overflow: 'hidden' };

  const editButtonStyle = props.squarePhoto
    ? {
        right: '-5%',
        bottom: '-5%',
        borderRadius: '50%',
        overflow: 'hidden',
      }
    : { right: '3%', bottom: '3%', borderRadius: '50%', overflow: 'hidden' };

  function addPhoto(photoFile: Blob) {
    agent.User.uploadProfilePhoto({
      signedPhotoUrl: user.photoUrl,
      photoFile,
    })
      .then((res) => {
        updateUser({ ...res.data, hasPhoto: res.data.photo });
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  }

  function editPhoto(photoFile: Blob) {
    agent.User.editProfilePhoto({
      signedPhotoUrl: user.photoUrl,
      photoFile,
    })
      .then((res) => {
        updateUser({ ...res.data, hasPhoto: res.data.photo });
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  }

  function deletePhoto() {
    agent.User.deleteProfilePhoto({ signedPhotoUrl: user.photoUrl })
      .then((res) => {
        updateUser({ ...res.data, hasPhoto: false });
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  }

  if (photoUrl && isWindowDefined) {
    return (
      <div
        className="h-100 w-100 position-relative"
        style={{ maxWidth: '160px' }}
      >
        <div
          className="bg-light h-100 mx-auto d-flex justify-content-center align-items-center w-100"
          style={imageStyle}
        >
          <img
            src={photoUrl}
            alt={alt}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        {allowEdit && (
          <>
            <div
              className="position-absolute p-1 bg-white"
              style={editButtonStyle}
            >
              <UploadPhotoModal
                hasPhoto={user.hasPhoto}
                photoUrl={photoUrl}
                resourceName="Profile"
                imageRadius={isStaffType(user) ? 300 : undefined}
                onAdd={addPhoto}
                onUpdate={editPhoto}
                onDelete={deletePhoto}
                photoErrorMessage={errorMessage}
              />
            </div>
          </>
        )}
      </div>
    );
  } else if (isThirdParty) {
    return (
      <div className="py-auto h-100 mx-auto d-flex justify-content-center align-items-center w-100 bg-secondary rounded-circle p-2 ">
        <FaExternalLinkAlt className="text-white" />
      </div>
    );
  } else {
    return (
      <div className="py-auto h-100 mx-auto d-flex justify-content-center align-items-center w-100 bg-secondary rounded-circle p-2 ">
        {resumePreview ? (
          <FaUser className="text-white fa-2x" />
        ) : (
          <StaticImage
            src="../../images/dentalpost-logo-white.png"
            placeholder="blurred"
            layout="constrained"
            alt="DentalPost logo"
          />
        )}
      </div>
    );
  }
};

export default ProfilePicture;
