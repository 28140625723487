import React, { useState, useEffect, useGlobal } from 'reactn';
import { PropsWithChildren } from 'react';
import agent from '../agent';
import types from '../types/services-api';

export type ApplicationsContextType = {
  applicationList: types.JobApplicationViewType[];
  updateStaffApplications: (
    additionalApplication: types.JobApplicationViewType
  ) => void;
};

const initialApplications: types.JobApplicationViewType[] = [];

export const ApplicationsContext = React.createContext<ApplicationsContextType>(
  {
    applicationList: initialApplications,
    updateStaffApplications: (additioanlApplication) => {},
  }
);

export function isApplication(obj: any): obj is types.JobApplicationViewType {
  return 'reviewedDate' in obj;
}

const ApplicationsContextWrapper = (props: PropsWithChildren) => {
  const [isStaff] = useGlobal('isStaff');
  const [applications, setApplications] = useState<
    types.JobApplicationViewType[]
  >([]);

  useEffect(() => {
    if (isStaff) {
      fetchApplications();
    }
  }, []);

  function fetchApplications() {
    agent.Staff.applications()
      .then((res) => {
        setApplications(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function updateStaffApplications(
    additionalApplication: types.JobApplicationViewType
  ) {
    setApplications([additionalApplication, ...applications]);
  }

  //for DevTools to display context name
  ApplicationsContext.displayName = 'Applications Context';

  return (
    <ApplicationsContext.Provider
      value={{
        applicationList: applications,
        updateStaffApplications,
      }}
    >
      {props.children}
    </ApplicationsContext.Provider>
  );
};

export default ApplicationsContextWrapper;
