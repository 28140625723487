import React from 'react';
import { Link } from 'gatsby';

// Recommended way to determine which Link is 'active' from @gatsbyjs/reach-router
const LinkWithActive = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? `${props.className} active` : props.className,
      };
    }}
  />
);

export default LinkWithActive;
