import React from 'react';
import { Button, Nav, NavLink } from 'reactstrap';
import classnames from 'classnames';
import IconHelper from '../common/IconHelper';
import Link from '../common/Link';
import UnreadMessages from '../messages/UnreadMessages';
import {
  toPremium,
  toDashboard,
  toProfile,
  toJobSearchResults,
  toAssessments,
  toConversationList,
  toCERequirements,
  toMyClassifieds,
  toAccountSettings,
  toJobPostings,
  toInterviews,
  toContactUs,
  toBlog,
  toEventsList,
  toClassifiedsList,
  toResources,
  toSubscription,
} from '../../routes';
import {
  UserContext,
  UserContextType,
  isEmployerType,
  isStaffType,
} from '../../context/UserContext';
import { useContext } from 'react';
import { JobPostingContext } from '../../context/JobPostingContext';

const Sidenav = (props: { toggle?: () => void }) => {
  const { user } = useContext(UserContext) as UserContextType;
  const { subscription } = useContext(JobPostingContext);

  const navLinks = isEmployerType(user)
    ? [
        {
          route: toDashboard,
          label: 'Dashboard',
          icon: 'FaHome',
        },
        {
          route: toJobPostings,
          label: 'Job Postings',
          icon: 'FaFileAlt',
          testId: 'jobPostings',
        },
        !!subscription
          ? {
              route: toSubscription,
              label: 'Subscription',
              icon: 'FaSync',
            }
          : {},
        {
          route: toProfile,
          label: 'Employer Profile',
          icon: 'FaBuilding',
        },
        {
          route: toAssessments,
          label: 'Assessments',
          icon: 'FaChartPie',
        },
        {
          route: toInterviews,
          label: 'Interviews',
          icon: 'FaCalendarAlt',
        },
        {
          route: toConversationList,
          label: 'Messages',
          icon: 'FaComments',
        },
        {
          route: toMyClassifieds,
          label: 'My Classifieds',
          icon: 'FaNewspaper',
        },
        {
          route: toAccountSettings,
          label: 'Settings',
          icon: 'FaCog',
        },
      ]
    : [
        {
          route: toDashboard,
          label: 'Dashboard',
          icon: 'FaHome',
        },
        {
          route: toProfile,
          label: 'Resume Profile',
          testId: 'profileLink',
          icon: 'FaAddressCard',
        },
        {
          route: toJobSearchResults(),
          label: 'Search Jobs',
          testId: 'searchJobs',
          icon: 'FaSearch',
        },
        {
          route: toAssessments,
          label: 'Assessments',
          testId: 'assessmentsLink',
          icon: 'FaChartPie',
        },
        {
          route: toInterviews,
          label: 'Interviews',
          icon: 'FaCalendarAlt',
        },
        {
          route: toConversationList,
          label: 'Messages',
          icon: 'FaComments',
        },
        {
          route: toPremium,
          label: 'Premium',
          icon: 'FaStar',
        },
        {
          route: toCERequirements,
          label: "My CE's",
          icon: 'FaBook',
        },
        {
          route: toMyClassifieds,
          label: 'My Classifieds',
          icon: 'FaNewspaper',
        },
        {
          route: toAccountSettings,
          label: 'Settings',
          icon: 'FaCog',
        },
      ];

  return (
    <div
      className={classnames('dp-sidenav h-100', {
        'dp-is-staff': isStaffType(user),
        'dp-is-employer': isEmployerType(user),
      })}
    >
      <Nav
        vertical
        pills
        className="p-2 flex-nowrap"
        style={{ overflowY: 'scroll', height: 'calc(100% - 120px)' }}
      >
        {navLinks
          .filter((link) => !!link.label && !link.dateCheck)
          .map((link) => (
            <NavLink
              key={link.label}
              tag={Link}
              to={link.route}
              className="dp-nav-link rounded my-1"
              onClick={!!props.toggle ? props.toggle : undefined}
            >
              {link.icon && (
                <span
                  className="d-inline-block text-center me-2"
                  style={{ width: '20px' }}
                >
                  <IconHelper icon={link.icon} />
                </span>
              )}
              {link.label}
              {link.label === 'Messages' && <UnreadMessages />}
            </NavLink>
          ))}{' '}
        <hr className="my-4 w-75 mx-auto" />
        <NavLink
          tag={Link}
          to={toResources}
          className="dp-nav-link rounded my-1"
          onClick={!!props.toggle ? props.toggle : undefined}
        >
          Resources
        </NavLink>
        <NavLink
          tag={Link}
          to={toBlog}
          onClick={!!props.toggle ? props.toggle : undefined}
          className="dp-nav-link rounded my-1"
        >
          Blog
        </NavLink>
        <NavLink
          tag={Link}
          to={toEventsList}
          onClick={!!props.toggle ? props.toggle : undefined}
          className="dp-nav-link rounded my-1"
        >
          Dental Events & CE's
        </NavLink>
        <NavLink
          tag={Link}
          to={toClassifiedsList()}
          onClick={!!props.toggle ? props.toggle : undefined}
          className="dp-nav-link rounded my-1"
        >
          Browse Classifieds
        </NavLink>
        <div style={{ height: '120px' }}></div>
      </Nav>
      <div
        className="bg-solids-off-white-50 p-3 border-top d-flex flex-column align-items-center"
        style={{ height: '120px', width: '225px' }}
      >
        <h4>Questions?</h4>
        <Button
          color="secondary"
          tag={Link}
          to={toContactUs}
          onClick={!!props.toggle ? props.toggle : undefined}
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

export default Sidenav;
