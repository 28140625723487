import React, { useEffect, useGlobal, useState } from 'reactn';
import { PropsWithChildren, useContext } from 'react';
import types from '../types/services-api';
import agent from '../agent';
import { GlobalContext } from './GlobalContext';
import analytics, {
  ADD_TO_CART_ACTION,
  ENGAGEMENT_CATEGORY,
  REMOVE_FROM_CART_ACTION,
} from '../analytics';

export type SkuType = 'BASIC' | 'JOB' | 'INV' | 'NAT' | 'JOBSUB' | 'INVSUB';

export type PurchaseContextType = {
  appliedDiscount?: {
    code: string;
    message: string;
  };
  cart: ProductCartType;
  resetCart: () => void;
  updateCart: (productCode: SkuType, quantity: number) => void;
  products:
    | {
        [key: string]: ProductFormattedType;
      }
    | undefined;
  getBestDiscount: (props: {
    productCode: SkuType;
    cartUpdateQuantity?: number;
    discounts?: { [key: string]: types.ProductDiscountType[] };
  }) => types.ProductDiscountType | void;
  couponErrorMessage: string;
  getCatalog: (discountCode?: string, sku?: SkuType) => void;
  pricingMap:
    | undefined
    | {
        [key: string]: {
          msrp: number;
          description: string;
          activationFee?: number;
          recurringFee?: number;
          totalFee: number;
          coupon: string;
          discount: number;
          quantity: number;
        };
      };
};

type ProductCombinedType = types.ProductSkuType & types.ProductDiscountType;
export type ProductFormattedType = ProductCombinedType | types.ProductSkuType;
export function isDiscountedType(obj: any): obj is ProductCombinedType {
  return !!obj && 'discountCode' in obj;
}

export type ProductCartType = {
  BASIC: number;
  JOB: number;
  INV: number;
  NAT: number;
  JOBSUB: number;
  INVSUB: number;
};

const STATIC_CART_DATA: ProductCartType = {
  BASIC: 1,
  JOB: 1,
  INV: 0,
  NAT: 0,
  JOBSUB: 1,
  INVSUB: 1,
};

export const PurchaseContext = React.createContext<PurchaseContextType>({
  appliedDiscount: undefined,
  cart: STATIC_CART_DATA,
  resetCart: () => {},
  updateCart: (productCode: SkuType, quantity: number) => {},
  products: undefined,
  getBestDiscount: (props: {
    productCode: string;
    cartUpdateQuantity?: number;
    apiDiscounts?: { [key: string]: types.ProductDiscountType[] };
  }) => {},
  couponErrorMessage: '',
  getCatalog: (discountCode?: string) => {},
  pricingMap: undefined,
});

const PurchaseContextWrapper = (props: PropsWithChildren) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const [isEmployer] = useGlobal('isEmployer');
  const { urlParams } = useContext(GlobalContext);
  const [catalog, setCatalog] = useState<
    | {
        [key: string]: types.ProductSkuType;
      }
    | undefined
  >(undefined);
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState<{
    code: string;
    message: string;
  }>();
  const [availableDiscounts, setAvailableDiscounts] = useState<{
    [key: string]: types.ProductDiscountType[];
  }>({});

  const [products, setProducts] = useState<
    | {
        [key: string]: types.ProductSkuType | ProductFormattedType;
      }
    | undefined
  >(undefined);

  const [cart, setCart] = useState<ProductCartType>(STATIC_CART_DATA);
  const [cartUpdateTracking, setCartUpdateTracking] = useState<
    | { productCode: string; quantity: number; action: 'add' | 'remove' }
    | undefined
  >(undefined);

  const pricingMap:
    | undefined
    | {
        [key: string]: {
          msrp: number;
          description: string;
          activationFee?: number;
          recurringFee?: number;
          totalFee: number;
          coupon: string;
          discount: number;
          quantity: number;
        };
      } = !!products
    ? {
        BASIC: {
          msrp: products.BASIC.fee,
          description: products.BASIC.description,
          activationFee: 0,
          recurringFee: 0,
          totalFee: isDiscountedType(products.BASIC)
            ? products.BASIC.discountFee
            : products.BASIC.fee,
          coupon: isDiscountedType(products.BASIC)
            ? products.BASIC.discountCode
            : '',
          discount: isDiscountedType(products.BASIC)
            ? products.BASIC.fee - products.BASIC.discountFee
            : 0,
          quantity: cart.BASIC,
        },
        JOB: {
          msrp: products.JOB.fee,
          description: products.JOB.description,
          activationFee: 0,
          recurringFee: 0,
          totalFee: isDiscountedType(products.JOB)
            ? products.JOB.discountFee
            : products.JOB.fee,
          coupon: isDiscountedType(products.JOB)
            ? products.JOB.discountCode
            : '',
          discount: isDiscountedType(products.JOB)
            ? products.JOB.fee - products.JOB.discountFee
            : 0,
          quantity: cart.JOB,
        },
        //Used when removing invite quantity from cart to return to 0
        INV: {
          msrp: products.INV.fee,
          description: products.INV.description,
          activationFee: 0,
          recurringFee: 0,
          totalFee:
            getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 50 * cart.JOB,
            })?.discountFee ?? products.INV.fee,
          coupon:
            getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 50 * cart.JOB,
            })?.discountCode ?? '',
          discount:
            products.INV.fee -
            (getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: cart.INV * 50 * cart.JOB,
            })?.discountFee ?? 0),
          quantity: 50 * cart.JOB,
        },
        INV50: {
          msrp: products.INV.fee,
          description: products.INV.description,
          activationFee: 0,
          recurringFee: 0,
          totalFee:
            getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 50,
            })?.discountFee ?? products.INV.fee,
          coupon:
            getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 50,
            })?.discountCode ?? '',
          discount:
            products.INV.fee -
            (getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 50,
            })?.discountFee ?? 0),
          quantity: 50 * cart.JOB,
        },
        INV100: {
          msrp: products.INV.fee,
          description: products.INV.description,
          activationFee: 0,
          recurringFee: 0,
          totalFee:
            getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 100 * cart.JOB,
            })?.discountFee ?? products.INV.fee,
          coupon:
            getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 100 * cart.JOB,
            })?.discountCode ?? '',
          discount:
            products.INV.fee -
            (getBestDiscount({
              productCode: 'INV',
              cartUpdateQuantity: 100 * cart.JOB,
            })?.discountFee ?? 0),
          quantity: 100 * cart.JOB,
        },
        NAT: {
          msrp: products.NAT.fee,
          description: products.NAT.description,
          activationFee: 0,
          recurringFee: 0,
          totalFee:
            getBestDiscount({
              productCode: 'NAT',
              cartUpdateQuantity: cart.JOB,
            })?.discountFee ?? products.NAT.fee,
          coupon:
            getBestDiscount({
              productCode: 'NAT',
              cartUpdateQuantity: cart.JOB,
            })?.discountCode ?? '',
          discount:
            products.NAT.fee -
            (getBestDiscount({
              productCode: 'NAT',
              cartUpdateQuantity: cart.JOB,
            })?.discountFee ?? products.NAT.fee),
          quantity: cart.JOB,
        },
        JOBSUB: {
          msrp: products.JOBSUB.fee,
          description: products.JOBSUB.description,
          activationFee: isDiscountedType(products.JOBSUB)
            ? products.JOBSUB.discountActivationFee
            : products.JOBSUB.activationFee,
          totalFee: isDiscountedType(products.JOBSUB)
            ? products.JOBSUB.discountFee
            : products.JOBSUB.fee,
          coupon: isDiscountedType(products.JOBSUB)
            ? products.JOBSUB.discountCode
            : '',
          discount: isDiscountedType(products.JOBSUB)
            ? products.JOBSUB.fee - products.JOBSUB.discountFee
            : 0,
          quantity: cart.JOBSUB,
        },
        INVSUB: {
          msrp: products.INVSUB.fee,
          description: products.INVSUB.description,
          recurringFee: 0,
          totalFee:
            getBestDiscount({
              productCode: 'INVSUB',
              cartUpdateQuantity: 50 * cart.JOBSUB,
            })?.discountFee ?? products.INV.fee,
          coupon:
            getBestDiscount({
              productCode: 'INVSUB',
              cartUpdateQuantity: 50 * cart.JOBSUB,
            })?.discountCode ?? '',
          discount:
            products.INV.fee -
            (getBestDiscount({
              productCode: 'INVSUB',
              cartUpdateQuantity: 50 * cart.JOBSUB,
            })?.discountFee ?? products.INV.fee),
          quantity: 50 * cart.JOBSUB,
        },
      }
    : undefined;

  useEffect(() => {
    getCatalog(urlParams.discountCode);
  }, [isAuthenticated, urlParams.discountCode]);

  function getCatalog(discountCode?: string) {
    setCouponErrorMessage('');
    if (isEmployer) {
      getAuthCatalog(discountCode);
    } else {
      getPublicCatalog(discountCode);
    }
  }

  function getPublicCatalog(discountCode?: string) {
    agent.Jobs.publicCatalog(discountCode)
      .then((res) => {
        formatData(res.data);
      })
      .catch((err) => {
        setCouponErrorMessage(err.message);
      });
  }

  function getAuthCatalog(discountCode?: string) {
    agent.Jobs.catalog(discountCode)
      .then((res) => {
        formatData(res.data);
      })
      .catch((err) => {
        setCouponErrorMessage(err.message);
      });
  }

  function formatData(apiResponse: types.CatalogViewType) {
    const apiCatalog = apiResponse.products;
    const apiDiscounts = formatDiscountData(apiResponse.discounts);
    const displayDiscount = apiResponse.discounts.filter(
      (obj) => !obj.discountCode.startsWith('_') && !!obj.displayPhrase
    )[0];
    if (!!displayDiscount) {
      setAppliedDiscount({
        code: displayDiscount.discountCode,
        message: displayDiscount.displayPhrase,
      });
    } else {
      setAppliedDiscount(undefined);
    }
    setAvailableDiscounts(apiDiscounts);
    setCatalog(formatProductData(apiCatalog));
    const formattedProducts = apiCatalog.map((product) => {
      if (!!apiDiscounts[product.productCode]) {
        const formattedProduct: ProductFormattedType = {
          ...product,
          ...getBestDiscount({
            productCode: product.productCode,
            discounts: formatDiscountData(apiResponse.discounts),
          }),
        };
        return formattedProduct;
      } else {
        return product;
      }
    });
    setProducts(formatProductData(formattedProducts));
  }

  function formatProductData(productArray: ProductFormattedType[]): {
    [key: string]: types.ProductSkuType;
  } {
    const formattedData = productArray.reduce(
      (acc, item) => ({
        ...acc,
        [item.productCode]: item,
      }),
      {}
    );
    return formattedData;
  }

  function getBestDiscount(props: {
    productCode: string;
    cartUpdateQuantity?: number;
    discounts?: { [key: string]: types.ProductDiscountType[] };
  }) {
    const { productCode, cartUpdateQuantity } = props;
    const discountsApplied = props.discounts
      ? props.discounts
      : availableDiscounts;
    const quantity = cartUpdateQuantity
      ? cartUpdateQuantity
      : cart[productCode];
    if (!!discountsApplied[props.productCode]) {
      const bestDiscount = discountsApplied[props.productCode].reduce(
        (
          prev: types.ProductDiscountType | undefined,
          curr: types.ProductDiscountType
        ) => {
          if (
            quantity >= curr.minimumQuantity &&
            quantity <= curr.maximumQuantity &&
            (!prev ||
              curr.discountFee < prev.discountFee ||
              curr.discountActivationFee < prev.discountActivationFee)
          ) {
            return curr;
          } else {
            return prev;
          }
        },
        undefined
      );
      return bestDiscount;
    }
  }

  function formatDiscountData(apiResponse: types.ProductDiscountType[]): {
    [key: string]: types.ProductDiscountType[];
  } {
    const formattedData = apiResponse.reduce((acc, item) => {
      return {
        ...acc,
        [item.productCode]: [...(acc[item.productCode] || []), item],
      };
    }, {});
    return formattedData;
  }

  useEffect(() => {
    if (
      !!cartUpdateTracking &&
      cart[cartUpdateTracking.productCode] === cartUpdateTracking.quantity
    ) {
      const { productCode, quantity } = cartUpdateTracking;
      const adjustedProductCode =
        productCode === 'INV' && quantity === 1
          ? 'INV50'
          : productCode === 'INV' && quantity === 2
            ? 'INV100'
            : productCode;
      const product = !!pricingMap
        ? pricingMap[`${adjustedProductCode}`]
        : undefined;

      if (!!product) {
        const analyticsPayload = {
          currency: 'USD',
          value:
            product.totalFee * product.quantity + (product.activationFee ?? 0),
          items: [
            {
              index: 0,
              item_id: adjustedProductCode,
              item_brand: 'DentalPost',
              item_name: product.description,
              coupon: product.coupon,
              activationFee: (product.activationFee ?? 0) * cart.JOBSUB,
              discount: product.discount * product.quantity,
              item_category: productCode.includes('SUB')
                ? 'Subscription'
                : '30-Day Posting',
              item_list_id: 'purchase_form',
              item_list_name: 'Purchase Form',
              price: product.totalFee * product.quantity,
              quantity: product.quantity,
            },
          ],
        };

        if (cartUpdateTracking.action === 'add') {
          analytics.Events.trackEvent({
            actionType: ADD_TO_CART_ACTION,
            category: ENGAGEMENT_CATEGORY,
            payload: analyticsPayload,
          });
        } else if (cartUpdateTracking.action === 'remove') {
          analytics.Events.trackEvent({
            actionType: REMOVE_FROM_CART_ACTION,
            category: ENGAGEMENT_CATEGORY,
            payload: analyticsPayload,
          });
        }
      }
    }
  }, [cart, cartUpdateTracking]);

  function updateCart(productCode: string, quantity: number) {
    if (quantity === 0 || (productCode === 'INVSUB' && quantity === 1)) {
      setCartUpdateTracking({
        productCode,
        quantity,
        action: 'remove',
      });
    } else if (quantity > 0) {
      setCartUpdateTracking({ productCode, quantity, action: 'add' });
    }
    setCart({
      ...cart,
      [productCode]: quantity,
    });
    //recalculate best discount when cart quantities change
    if (!!catalog && !!availableDiscounts) {
      const updatedProduct = {
        ...catalog[productCode],
        ...getBestDiscount({
          productCode: productCode,
          cartUpdateQuantity: quantity,
          discounts: availableDiscounts,
        }),
      };
      setProducts({
        ...products,
        [productCode]: updatedProduct,
      });
    }
  }

  function resetCart() {
    setCart(STATIC_CART_DATA);
  }

  //for DevTools to display context name
  PurchaseContext.displayName = 'Purchase Context';

  return (
    <PurchaseContext.Provider
      value={{
        appliedDiscount,
        cart,
        resetCart,
        updateCart,
        products,
        getBestDiscount,
        couponErrorMessage,
        getCatalog,
        pricingMap,
      }}
    >
      {props.children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseContextWrapper;
