import axios, { Axios } from 'axios';
import { encodedUsername } from './utils/authentication';
/*
 * ------------------------------
 * Hubspot
 * ------------------------------
 */
const portalId = process.env.NODE_ENV === 'production' ? '4218133' : '22724222';

const formGuids =
  process.env.NODE_ENV === 'production'
    ? {
        'event-submission': '5efad804-7f8e-42e7-837b-65075bc4f4f0',
        'jobseeker-toolkit': '8fb88089-9d90-4fe9-aabe-b7fb68ffa793',
        'hiring-toolkit': '88f60a8d-07c8-4888-8822-8169cefe2bbb',
        newsletter: 'd4bc336c-a526-42c7-9ba4-7de26e892f73',
        'student-webinar': 'a9033295-51ab-4974-a698-9d7fa22ef4c6',
        'lead-form': '0f65b3a5-7218-4687-bb6f-56c4318d2ec5',
      }
    : {
        'event-submission': 'e3ac993f-7bb8-4d69-8a24-8135efe3390e',
        'jobseeker-toolkit': '28dc5ea0-e318-4a2e-9c44-bc7c75da2ef7',
        'hiring-toolkit': 'bbdbbd8e-9d12-460c-8a02-e80e566ca06c',
        newsletter: '40762718-8046-47b8-bb2f-1d1aa5914734',
        'student-webinar': 'e63b2352-804d-4ff1-a3ab-3f6513480f0c',
        'lead-form': '669f65bf-8600-4179-944e-a1e928ea779d',
      };

interface HubspotForm {
  formName?: string;
  leadSource?: string;
  assetTitle?: string;
  email: string;
  userRole?: 'employer' | 'staff' | 'lead';
  name?: string;
  phone?: string;
  leadPracticeName?: string;
  leadZip?: string;
  positionCode?: 'DD' | 'DH' | 'DA' | 'FO' | 'SR' | 'LT';
  role?: 'employer' | 'staff' | 'student' | 'other';
  roleOther?: string;
  eventTitle?: string;
  eventUrl?: string;
  eventType?: 'In-person' | 'Virtual' | 'Hybrid';
  eventVenue?: string;
  eventCity?: string;
  eventState?: string;
  eventZip?: string;
  eventStartDate?: string;
  eventEndDate?: string;
  eventDescription?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
}

const Hubspot = {
  submitEmailPreferencesForm: function (data = undefined) {
    return axios.post(
      `/api9/users/${encodedUsername()}/emails/preferences`,
      data
    );
  },
  submitHubspotForm: function (props: HubspotForm) {
    return axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${
        formGuids[!!props.formName ? props.formName : 'lead-form']
      }`,
      {
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: props.email,
          },
          ...(!!props.name
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__${props.userRole}_name`,
                  value: props.name,
                },
              ]
            : []),
          ...(!!props.leadPracticeName
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__lead_practice_name`,
                  value: props.leadPracticeName,
                },
              ]
            : []),
          ...(!!props.leadZip
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__lead_zip`,
                  value: props.leadZip,
                },
              ]
            : []),
          ...(!!props.phone
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__${props.userRole}_phone`,
                  value: props.phone,
                },
              ]
            : []),
          ...(!!props.positionCode
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__${props.userRole}_position_code`,
                  value: props.positionCode,
                },
              ]
            : []),
          ...(!!props.leadSource
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__lead_source`,
                  value: props.leadSource,
                },
              ]
            : []),
          ...(!!props.assetTitle
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__last_report_download`,
                  value: props.assetTitle,
                },
              ]
            : []),
          ...(!!props.role
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__lead_role`,
                  value: props.role,
                },
              ]
            : []),
          ...(!!props.roleOther
            ? [
                {
                  objectTypeId: '0-1',
                  name: `dp__lead_role_other`,
                  value: props.roleOther,
                },
              ]
            : []),
          ...(!!props.eventTitle
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_title',
                  value: props.eventTitle,
                },
              ]
            : []),
          ...(!!props.eventUrl
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_url',
                  value: props.eventUrl,
                },
              ]
            : []),
          ...(!!props.eventType
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_type',
                  value: props.eventType,
                },
              ]
            : []),
          ...(!!props.eventVenue
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_venue',
                  value: props.eventVenue,
                },
              ]
            : []),
          ...(!!props.eventCity
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_city',
                  value: props.eventCity,
                },
              ]
            : []),
          ...(!!props.eventState
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_state',
                  value: props.eventState,
                },
              ]
            : []),
          ...(!!props.eventZip
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_zip',
                  value: props.eventZip,
                },
              ]
            : []),
          ...(!!props.eventStartDate
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_start_date',
                  value: props.eventStartDate,
                },
              ]
            : []),
          ...(!!props.eventEndDate
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_end_date',
                  value: props.eventEndDate,
                },
              ]
            : []),
          ...(!!props.eventDescription
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__user_event_description',
                  value: props.eventDescription,
                },
              ]
            : []),
          ...(!!props.utmSource
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__utm_source',
                  value: props.utmSource,
                },
              ]
            : []),
          ...(!!props.utmMedium
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__utm-medium',
                  value: props.utmMedium,
                },
              ]
            : []),
          ...(!!props.utmCampaign
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__utm_campaign',
                  value: props.utmCampaign,
                },
              ]
            : []),
          ...(!!props.utmContent
            ? [
                {
                  objectTypeId: '0-1',
                  name: 'dp__utm_content',
                  value: props.utmContent,
                },
              ]
            : []),
        ],
        context: {
          pageUri: window.location.href,
        },
      }
    );
  },
};

export default Hubspot;
