import React, { PropsWithChildren } from 'react';

const OutboundLink = (
  props: PropsWithChildren<{
    className?: string;
    href: string;
  }>
) => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

export default OutboundLink;
