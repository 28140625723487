import React, { useContext } from 'react';
import { Badge } from 'reactstrap';
import { ConversationsContext } from '../../context/ConversationsContext';

const UnreadMessages = () => {
  const { unreadMessages } = useContext(ConversationsContext);

  if (unreadMessages === 0) {
    return null;
  }
  return (
    <Badge color="primary" className="ms-2">
      {unreadMessages}
    </Badge>
  );
};

export default UnreadMessages;
