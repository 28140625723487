import React, { useState, useGlobal, useEffect } from 'reactn';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Col, NavItem, NavLink, Button, Container, Row } from 'reactstrap';
import { toBlog, toBlogPostDetail } from '../../routes';
import { dpOrange } from '../../utils/displayHelper';
import { BlogPostFeaturedListType } from '../../types/wordpress';
import dayjs from 'dayjs';
import { NavCategoriesType } from './Layout';
import { FaCaretDown } from 'react-icons/fa';

const FlyoutMenu = (props: { navCategories: NavCategoriesType }) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const [isEmployer] = useGlobal('isEmployer');

  const { employerPosts, staffPosts, unauthenticatedPosts } =
    useStaticQuery(blogQuery);
  const [activeTab, setActiveTab] = useState('');
  const [blogPosts, setBlogPosts] =
    useState<BlogPostFeaturedListType>(unauthenticatedPosts);

  useEffect(() => {
    const updatedBlogPosts = !isAuthenticated
      ? unauthenticatedPosts
      : isEmployer
        ? employerPosts
        : staffPosts;
    setBlogPosts(updatedBlogPosts);
  }, [isAuthenticated]);

  function toggleActiveTab(tabId: string) {
    if (activeTab === tabId) {
      setActiveTab('');
    } else {
      setActiveTab(tabId);
    }
  }

  const getStyle = (tabId: string) => {
    return activeTab === tabId ? hoverStyle : {};
  };

  const hoverStyle = {
    borderBottomColor: dpOrange,
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
  };

  return (
    <div
      className="d-none d-xl-flex h-100 justify-content-center align-items-center mx-auto"
      onMouseLeave={() => setActiveTab('')}
    >
      {props.navCategories.map((category, categoryIndex) => {
        const isActive = activeTab === `tab-${categoryIndex}`;
        return (
          <div
            key={`dropdown-${categoryIndex}`}
            className="h-100 d-flex"
            style={getStyle(`tab-${categoryIndex}`)}
          >
            <Button
              color="white"
              className="d-flex h-100 align-items-center mx-3 rounded-0"
              onClick={() => toggleActiveTab(`tab-${categoryIndex}`)}
              onMouseEnter={() => setActiveTab(`tab-${categoryIndex}`)}
            >
              {category.title} <FaCaretDown />
            </Button>
            <div
              className={`bg-white w-100 shadow mega-nav-dropdown-content ${
                isActive ? 'd-flex' : 'd-none'
              }`}
              style={{
                position: 'absolute',
                top: '84px',
                left: 0,
                height: isActive ? '400px' : 0,
                width: '100vw',
                overflow: isActive ? undefined : 'hidden',
                transition: 'height 1s linear',
                zIndex: 2000,
              }}
              onMouseLeave={() => toggleActiveTab(``)}
            >
              <Container fluid className="p-4">
                <Row>
                  <Col md="5" className="mx-auto">
                    <p className="h3 mb-3">{category.mainNav.header}</p>
                    <p>{category.mainNav.description}</p>
                    <Container fluid className="px-0">
                      <Row className="me-2">
                        {category.mainNav.links.map((link, linkIndex) => {
                          return (
                            <NavItem
                              className="col-6 text-start py-2"
                              key={`desktop-${category.title}-main-link-${linkIndex}`}
                              style={{ listStyle: 'none' }}
                            >
                              <NavLink
                                tag={Link}
                                className="text-primary"
                                to={link.route}
                                onClick={() => toggleActiveTab('')}
                              >
                                {link.label}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Row>
                    </Container>
                    {!!category.mainNav.cta && (
                      <div className="mt-5">
                        <Button
                          color="primary"
                          tag={Link}
                          to={category.mainNav.cta.route}
                          onClick={() => toggleActiveTab('')}
                        >
                          {category.mainNav.cta.label}
                        </Button>
                      </div>
                    )}
                  </Col>
                  <Col xs="auto" className="border-end ps-0">
                    {' '}
                  </Col>
                  <Col md="6" className="mx-auto">
                    {!!category.featureNav && (
                      <>
                        <p className="h3 mb-3">{category.featureNav.header}</p>
                        {category.featureNav.links.map((link, linkIndex) => {
                          return (
                            <NavItem
                              className="py-2"
                              key={`desktop-${category.title}-feature-link-${linkIndex}`}
                              style={{ listStyle: 'none' }}
                            >
                              <NavLink
                                tag={Link}
                                to={link.route}
                                onClick={() => toggleActiveTab('')}
                              >
                                <Container fluid className="px-0 text-black">
                                  <Row>
                                    <Col xs="auto">
                                      <div style={{ width: '50px' }}>
                                        {link.icon}
                                      </div>
                                    </Col>
                                    <Col className="text-left ps-0">
                                      <p className="fw-bold mb-0">
                                        {link.header}
                                      </p>
                                      <p className="mb-0">
                                        {link.description}{' '}
                                        <span className="text-primary">
                                          Learn More
                                        </span>
                                      </p>
                                    </Col>
                                  </Row>
                                </Container>
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </>
                    )}
                    {category.blogNav && (
                      <>
                        <div className="d-flex mb-3 align-items-center">
                          <p className="h3 mb-3">Our Blog</p>
                          <Button
                            tag={Link}
                            to={toBlog}
                            className="ms-auto"
                            color="primary"
                            onClick={() => toggleActiveTab('')}
                          >
                            View all blog posts
                          </Button>
                        </div>
                        {!!blogPosts &&
                          blogPosts.nodes.map((blog) => {
                            const {
                              id,
                              slug,
                              title,
                              featuredImage: {
                                node: { altText, localFile },
                              },
                              date,
                            } = blog;
                            const gatsbyImage = getImage(localFile);
                            return (
                              <NavItem
                                key={`desktop-blog-link-${id}`}
                                style={{ listStyle: 'none' }}
                                className="mb-3"
                              >
                                <NavLink
                                  tag={Link}
                                  to={toBlogPostDetail(slug)}
                                  onClick={() => toggleActiveTab('')}
                                >
                                  <Container fluid className="px-0">
                                    <Row>
                                      <Col
                                        xs="auto"
                                        style={{ maxWidth: '175px' }}
                                      >
                                        {!!gatsbyImage && (
                                          <GatsbyImage
                                            loading="lazy"
                                            image={gatsbyImage}
                                            alt={altText}
                                            style={{
                                              pointerEvents: 'none',
                                            }}
                                          />
                                        )}
                                      </Col>
                                      <Col className="text-left text-black">
                                        <p
                                          className="fw-bold mb-0"
                                          style={{
                                            lineHeight: 1.5,
                                            height: '3em',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {title}
                                        </p>
                                        <p className="mb-0">
                                          <small>
                                            Posted{' '}
                                            {dayjs(date).format(
                                              'MMMM DD, YYYY'
                                            )}
                                          </small>
                                        </p>
                                      </Col>
                                    </Row>
                                  </Container>
                                </NavLink>
                              </NavItem>
                            );
                          })}
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FlyoutMenu;

export const blogQuery = graphql`
  query ($featuredImageWidth: Int = 400, $featuredImageHeight: Int = 200) {
    employerPosts: allWpPost(
      limit: 3
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 7804 } } } }
      }
      sort: { date: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
    staffPosts: allWpPost(
      limit: 3
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 7786 } } } }
      }
      sort: { date: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
    unauthenticatedPosts: allWpPost(
      limit: 3
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 4 } } } }
      }
      sort: { date: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
  }
`;
