import React, { useState, useGlobal, useEffect, useContext } from 'reactn';
import { PropsWithChildren } from 'react';
import agent from '../agent';
import {
  UserContext,
  UserContextType,
  isEmployerType,
  isStaffType,
} from './UserContext';
import types from '../types/services-api';
import { OfficesContext, OfficesContextType } from './ProfileContext';
import { JobPostingCSVJobType, JobPostingCSVPartnerType } from '../types/csv';
import { toJobSearchResults } from '../routes';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';

export type JobSearchParamsType = {
  'days-old'?: string;
  endDate?: string;
  keyword?: string;
  position?: string;
  radius?: string;
  startDate?: string;
  type?: string;
  zip?: string;
  evaluation?: string;
};

type JobType = types.JobInternalViewType | types.PartnerJobDetailViewType;

export type JobSearchContextType = {
  defaultMapCenter: { lat: string; lng: string } | null;
  searchJobs: (searchParams: JobSearchParamsType) => void;
  jobsList: JobType[] | undefined;
  premiumJobs: types.JobInternalViewType[] | null;
  updatePremiumJob: (updatedValues: types.JobInternalViewType) => void;
  partnerJobs: types.PartnerJobDetailViewType[] | null;
  updatePartnerJob: (updatedValues: types.PartnerJobDetailViewType) => void;
  searchErrorMessage: string;
  jobSearchParams: JobSearchParamsType | null;
  updateJobSearchParams: (updatedValues: JobSearchParamsType) => void;
};

export const JobSearchContext = React.createContext<JobSearchContextType>({
  defaultMapCenter: null,
  searchJobs: () => {},
  jobsList: undefined,
  premiumJobs: null,
  updatePremiumJob: () => {},
  partnerJobs: null,
  updatePartnerJob: () => {},
  searchErrorMessage: '',
  jobSearchParams: null,
  updateJobSearchParams: () => {},
});

const JobSearchContextWrapper = (props: PropsWithChildren) => {
  const { user } = useContext(UserContext) as UserContextType;
  const { primaryOffice } = useContext(OfficesContext) as OfficesContextType;
  const [jobsList, setJobsList] = useState<JobType[] | undefined>(undefined);
  const [premiumJobs, setPremiumJobs] = useState<
    types.JobInternalViewType[] | null
  >(null);
  const [partnerJobs, setPartnerJobs] = useState<
    types.PartnerJobDetailViewType[] | null
  >(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [jobSearchParams, setJobSearchParams] = useState<JobSearchParamsType>({
    type: 'ANY',
  });
  const [defaultMapCenter, setDefaultMapCenter] = useState<{
    lat: string;
    lng: string;
  } | null>(null);

  useEffect(() => {
    if (isStaffType(user)) {
      setDefaultMapCenter({ lat: user.lat, lng: user.lng });
    } else if (isEmployerType(user) && !!primaryOffice) {
      setDefaultMapCenter({ lat: primaryOffice.lat, lng: primaryOffice.lng });
    }
  }, [user, primaryOffice]);

  useEffect(() => {
    if (
      !!jobSearchParams &&
      !!jobSearchParams.position &&
      !!jobSearchParams.zip &&
      jobSearchParams.zip !== 'undefined'
    ) {
      searchJobs();
    }
  }, [jobSearchParams]);

  useEffect(() => {
    if (!!premiumJobs && !!partnerJobs) {
      setJobsList([...premiumJobs, ...partnerJobs]);
    }
  }, [premiumJobs, partnerJobs]);

  function updateJobSearchParams(updatedValues: JobSearchParamsType) {
    setJobSearchParams({ ...jobSearchParams, ...updatedValues });
  }

  function searchJobs() {
    if (!!jobSearchParams.position && !!jobSearchParams.zip) {
      setJobsList([]);
      setPremiumJobs(null);
      setPartnerJobs(null);
      setSearchErrorMessage('');
      //distance calculations will default to be from users lat/lng address instead of overall zip
      //only pass zip to api if different from their home address zip
      let transformedParams = { ...jobSearchParams };
      if (isStaffType(user) && user.zip === jobSearchParams.zip) {
        transformedParams.zip = undefined;
      }
      agent.Jobs.search(transformedParams)
        .then((res) => {
          setPremiumJobs(
            res.data.premiumJobs.filter((job) => {
              const evalFilter =
                jobSearchParams.evaluation === 'LIKED'
                  ? job.isLike
                  : jobSearchParams.evaluation === 'DISLIKED'
                  ? job.isDislike
                  : true;
              const startDateFilter = !!jobSearchParams.startDate
                ? job.jobDates.some(
                    (date) =>
                      !dayjs(date.date).isBefore(
                        dayjs(jobSearchParams.startDate)
                      )
                  )
                : true;
              const endDateFilter = !!jobSearchParams.endDate
                ? job.jobDates.some(
                    (date) =>
                      !dayjs(date.date).isAfter(dayjs(jobSearchParams.endDate))
                  )
                : true;
              return evalFilter && startDateFilter && endDateFilter;
            })
          );
          setPartnerJobs(res.data.partnerJobs);
        })
        .catch((err) => {
          if (err && err.message) {
            setSearchErrorMessage(err.message);
          } else {
            setSearchErrorMessage('Failed to search for jobs');
          }
        });
    }
  }

  function updatePremiumJob(updatedItem: types.JobInternalViewType) {
    const updatedPremiumJobs = !!premiumJobs
      ? premiumJobs.map((pj) => {
          return pj.id === updatedItem.id
            ? {
                ...pj,
                ...updatedItem,
              }
            : pj;
        })
      : [updatedItem];

    setPremiumJobs(updatedPremiumJobs);
  }

  function updatePartnerJob(updatedItem: types.PartnerJobDetailViewType) {
    const updatedExternalJobs = !!partnerJobs
      ? partnerJobs.map((ej) => {
          return ej.jobKey === updatedItem.jobKey
            ? {
                ...ej,
                ...updatedItem,
              }
            : ej;
        })
      : [updatedItem];
    setPartnerJobs(updatedExternalJobs);
  }

  JobSearchContext.displayName = 'Job Search Context';

  return (
    <JobSearchContext.Provider
      value={{
        defaultMapCenter,
        jobSearchParams,
        searchJobs,
        jobsList,
        premiumJobs,
        updatePremiumJob,
        partnerJobs,
        updatePartnerJob,
        searchErrorMessage,
        updateJobSearchParams,
      }}
    >
      {props.children}
    </JobSearchContext.Provider>
  );
};

export default JobSearchContextWrapper;

export function isCsvJob(obj: any): obj is JobPostingCSVJobType {
  return 'jobId' in obj && 'occupationalCategory' in obj;
}

export function isPartnerCsvJob(obj: any): obj is JobPostingCSVPartnerType {
  return 'jobKey' in obj && !('distance' in obj);
}

export function isPartnerAuthJob(
  obj: any
): obj is types.PartnerJobDetailViewType {
  return 'jobKey' in obj && 'distance' in obj;
}

export function isDpAuthJob(obj: any): obj is types.JobInternalViewType {
  return 'appliedDate' in obj;
}
