import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingSpinner = (props) => {
  return (
    <div className="d-flex align-items-center text-center py-5">
      <Spinner color="primary" className="me-3" />
      <p className="mb-0">Loading...</p>
    </div>
  );
};

export default LoadingSpinner;
