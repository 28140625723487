import React, { useState, useEffect, useGlobal } from 'reactn';
import { groupBy } from '../utils/displayHelper';
import types from '../types/services-api';
import agent from '../agent';
import { PropsWithChildren } from 'react';

type InterviewContextType = {
  errorMessage: string;
  interviews: types.UserInterviewViewType[];
  interviewsByDate: { [key: string]: types.UserInterviewViewType[] };
  positionOptions: string[] | null;
  updateInterviews: (updatedInterview: types.UserInterviewViewType) => void;
};

export const InterviewContext = React.createContext<InterviewContextType>({
  errorMessage: '',
  interviews: [],
  interviewsByDate: {},
  positionOptions: null,
  updateInterviews: () => {},
});

const InterviewContextWrapper = (props: PropsWithChildren) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const [interviews, setInterviews] = useState<types.UserInterviewViewType[]>(
    []
  );
  const [interviewsByDate, setInterviewsByDate] = useState<{
    [key: string]: types.UserInterviewViewType[];
  }>({});
  const [positionOptions, setPositionOptions] = useState<string[] | null>(null);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      agent.User.getInterviews({ date: 'all' })
        .then((res) => {
          setInterviews(res.data);
          const groupedInterviews = groupBy(res.data, 'interviewDate');
          setInterviewsByDate(groupedInterviews);
          const updatedPositions = Array.from(
            new Set(
              res.data.map(
                (interview: types.UserInterviewViewType) => interview.position
              )
            )
          );
          setPositionOptions(updatedPositions);
        })
        .catch((err) => {
          setErrorMessage(err.message);
        });
    }
  }, [isAuthenticated]);

  function updateInterviews(updatedInterview: types.UserInterviewViewType) {
    const updatedInterviews = interviews.map((interview) => {
      if (interview.jobId === updatedInterview.jobId) {
        return updatedInterview;
      } else {
        return interview;
      }
    });
    const updatedInterviewsByDate = groupBy(updatedInterviews, 'interviewDate');
    setInterviews(updatedInterviews);
    setInterviewsByDate(updatedInterviewsByDate);
  }

  InterviewContext.displayName = 'Interview Context';

  return (
    <InterviewContext.Provider
      value={{
        errorMessage,
        interviews,
        interviewsByDate,
        positionOptions,
        updateInterviews,
      }}
    >
      {props.children}
    </InterviewContext.Provider>
  );
};

export default InterviewContextWrapper;
