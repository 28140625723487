import React from 'react';
import axios from 'axios';
import './src/styles.scss';
import GlobalContextWrapper from './src/context/GlobalContext';
import Layout from './src/components/layout/Layout';
import 'bootstrap/js/dist/collapse';
// configure axios for all API calls
axios.defaults.baseURL = process.env.GATSBY_API_URL || '';

export const wrapRootElement = ({ element }) => {
  return <GlobalContextWrapper>{element}</GlobalContextWrapper>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {props.location?.pathname?.includes('/dental-jobs/purchase-credit/') ||
      props.location?.pathname?.includes('/app/welcome/') ||
      /\/resources\/.+/.test(props.location?.pathname) ? (
        <div className="bg-white m-0 p-0" style={{ height: '100dvh' }}>
          {element}
        </div>
      ) : (
        <Layout location={props.location}>{element}</Layout>
      )}
    </>
  );
};
