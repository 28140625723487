import { encodedUsername } from './utils/authentication';
export const rootPath = '/';
export const appPrefix = '/app';

// ---------------------
// URL Params
// ---------------------
const JOB_ID_PARAM = ':jobId';
const SLOT_ID_PARAM = ':slotId';
const JOB_SLUG_PARAM = ':jobSlug';
const POSITION_PARAM = ':position';
const ZIP_PARAM = ':zip';
const USERNAME_PARAM = ':username';
const EMPLOYER_PARAM = ':employer';
const CONVERSATION_ID_PARAM = ':conversationId';
const EDIT_RESUME_PROFILE_RESUME_CONTENT = '#editResumeContent';
const EDIT_RESUME_PROFILE_MODAL_CONTENT = `#addModalContent`;
const EDIT_RESUME_PROFILE_INTRODUCTION = `#editIntroduction`;

// ---------------------
// Authenticated routes
// ---------------------

// New Employer Registers
export const welcomePath = `welcome`;
export const toWelcome = `/app/welcome`;

// Staff or Employer views their Dashboard
export const dashboardPath = `dashboard`;
export const toDashboard = `/app/dashboard`;

// Staff or Employer views their Discover DentalPost page
export const discoverPath = `discover`;
export const toDiscover = `/app/discover`;

// Staff or Employer views Background Checks
export const backgroundChecksPath = `background-checks`;
export const toBackgroundChecks = `/app/background-checks`;

//Employer views Subscription
export const subscriptionPath = `subscription`;
export const toSubscription = `/app/subscription`;

// Staff or Employer views their Profile
export const profilePath = `my-profile`;
export const toProfile = `/app/my-profile`;
export const editPath = `edit`;
export const toProfileEdit = `/app/my-profile/edit`;
export const toProfileEditResumeContent = `/app/my-profile/edit${EDIT_RESUME_PROFILE_RESUME_CONTENT}`;
export const toProfileEditModalContent = `/app/my-profile/edit${EDIT_RESUME_PROFILE_MODAL_CONTENT}`;
export const toProfileEditIntroduction = `/app/my-profile/edit${EDIT_RESUME_PROFILE_INTRODUCTION}`;
export const legacyProfilePath = `/app/dashboard/profile`;

// Staff or Employer views Job Search
export const searchJobsPath = `search-jobs`;
export const searchJobsResultsPath = `${POSITION_PARAM}/${ZIP_PARAM}`;
export const toJobSearchResults = (
  position?: string,
  zip?: string,
  payload?: {}
) =>
  `/app/search-jobs/${position}/${zip}/${
    !!payload ? `?${Object.keys(payload)}=${Object.values(payload)}` : ''
  }`;

// Staff views a list of their Job Applications
export const myApplicationsPath = `all`;
export const toMyApplications = `/app/job-application/all`;

//Staff or Employer views Scheduled Interviews
export const interviewsPath = `interviews`;
export const toInterviews = `/app/interviews`;

// Staff or Employer views Job Posting
export const jobSearchJobDetailPath = `post-job/${JOB_ID_PARAM}/${JOB_SLUG_PARAM}`;
export const toJobSearchJobDetail = (
  jobId: number | string,
  jobSlug?: string
) =>
  `/app/search-jobs/post-job/${jobId}/${
    !!jobSlug ? `${jobSlug}/` : 'dynamic-redirect/'
  }`;
export const jobSearchApplicationPath = `post-job/application/${JOB_ID_PARAM}`;
export const toJobSearchApplicationDetail = (jobId) =>
  `/app/search-jobs/post-job/application/${jobId}/`;
export const jobSearchEmployerPath = `post-job/employer/${EMPLOYER_PARAM}/${JOB_ID_PARAM}`;
export const toJobSearchEmployerDetail = (jobId, employer) =>
  `/app/search-jobs/post-job/employer/${encodedUsername(employer)}/${jobId}`;

// Employer Job Posting pages
export const jobPostingPath = `post-job`;
export const jobPostingsPath = `all`;
export const toJobPostings = `/app/post-job/all`;
export const jobPostingSummaryPath = `summary/${JOB_ID_PARAM}`;
export const toJobPostingSummary = (jobId: number, username?: string) =>
  !!username
    ? `/app/post-job/summary/${jobId}?u=${username}`
    : `/app/post-job/summary/${jobId}`;
export const jobSlotEditPath = `subscription/${SLOT_ID_PARAM}`;
export const toJobSlotEdit = (slotId) => `/app/post-job/subscription/${slotId}`;
export const toJobPostingCreate = `/app/post-job/edit/new/`;
export const jobPostingEditPath = `edit/${JOB_ID_PARAM}`;
export const toJobPostingEdit = (jobId) => `/app/post-job/edit/${jobId}`;
export const jobPostingPurchasePath = `purchase`;
export const toJobPostingPurchase = `/app/post-job/purchase`;

// Employer views a Job Application
export const jobApplicationPath = `job-application`;
export const jobApplicationDetailPath = `${JOB_ID_PARAM}/${USERNAME_PARAM}`;
export const toJobApplicationDetail = (jobId, staffUsername) =>
  `/app/job-application/${jobId}/${staffUsername}`;

// Employer views purchase history
export const viewReceiptPath = `receipt/:paymentId`;
export const toViewReceipt = (paymentId) =>
  `/app/account-settings/receipt/${paymentId}`;

// Conversations and Messages
export const conversationListPath = `messages`;
export const toConversationList = `/app/messages`;
export const conversationDetailPath = `${CONVERSATION_ID_PARAM}`;
export const toConversationDetail = (conversationId) =>
  `/app/messages/${conversationId}`;

// Premium Account
export const premiumPath = `premium`;
export const toPremium = `/app/premium`;

// Assessments
export const assessmentsPath = `assessments`;
export const toAssessments = `/app/assessments`;
export const discAssessmentPath = `disc`;
export const toDiscAssessment = `/app/assessments/disc`;
export const discResultsPath = `disc/results`;
export const toDiscResults = `/app/assessments/disc/results`;
export const coreValuesAssessmentPath = `core-values`;
export const toCoreValuesAssessment = `/app/assessments/core-values`;
export const coreValuesResultsPath = `core-values/results`;
export const toCoreValuesResults = `/app/assessments/core-values/results`;
export const cultureMultipleChoiceAssessmentPath = `culture-q`;
export const toCultureMultipleChoiceAssessment = `/app/assessments/culture-q`;
export const culturePreferenceQuestionsAssessmentPath = `culture-pq`;
export const toCulturePreferenceQuestionsAssessment = `/app/assessments/culture-pq`;
export const cultureResultsPath = `culture/results`;
export const toCultureResults = `/app/assessments/culture/results`;
export const alignmentAssessmentPath = `alignment`;
export const toAlignmentAssessment = `/app/assessments/alignment`;
export const alignmentResultsPath = `alignment/results`;
export const toAlignmentResults = `/app/assessments/alignment/results`;
export const satisfactionAssessmentPath = `satisfaction`;
export const toSatisfactionAssessment = `/app/assessments/satisfaction`;
export const satisfactionResultsPath = `satisfaction/results`;
export const toSatisfactionResults = `/app/assessments/satisfaction/results`;
export const skillsAssessmentPath = `skills`;
export const toSkillsAssessment = `/app/assessments/skills`;
export const skillsResultsPath = `skills/results`;
export const toSkillsResults = `/app/assessments/skills/results`;
export const emotionalIntelligenceAssessmentPath = `emotional-intelligence`;
export const toEmotionalIntelligenceAssessment = `/app/assessments/emotional-intelligence`;
export const emotionalIntelligenceResultsPath = `emotional-intelligence/results`;
export const toEmotionalIntelligenceResults = `/app/assessments/emotional-intelligence/results`;

export const technologyAssessmentPath = `technology`;
export const toTechnologyAssessment = `/app/assessments/technology`;

export const myClassifiedsPath = `my-classifieds`;
export const toMyClassifieds = `/app/my-classifieds`;
export const legacyClassifiedsPath = `/app/classifieds`;
export const myClassifiedsPhotosPath = `:listingId/photos`;
export const toMyClassifiedsPhotos = (listingId) =>
  `/app/my-classifieds/${listingId}/photos`;
export const newClassifiedListingPath = `new`;
export const toNewClassifiedListing = `/app/my-classifieds/new`;

export const accountSettingsPath = `account-settings`;
export const toAccountSettings = `/app/account-settings`;
export const legacyAccountSettingsPath = `/app/dashboard/account-settings`;

export const signOutPath = `sign-out`;
export const toSignOut = `/app/sign-out`;

// ---------------------
// Unauthenticated routes
// ---------------------
export const toSignIn = `/dental-jobs/login/`;
export const delegatedLoginReportsPath = `reports/login`;
export const toForgotPassword = `/dental-jobs/recover-password/`;
export const toResetPassword = `/dental-jobs/reset-password/`;

export const toEmployerLearnMore = `/dental-hiring-products/`;
export const toBasicProductMarketing = `/dental-hiring-products/basic/`;
export const toPremiumProductMarketing = `/dental-hiring-products/premium/`;
export const toSubscriptionProductMarketing = `/dental-hiring-products/subscription/`;

export const toStaffLearnMore = `/dental-jobs/professional/`;

export const toResources = `/dental-jobs/resources/`;

export const toGetStarted = `/dental-jobs/register/`;

export const toStaffRegistration = `/dental-jobs/register/staff/`;
export const toEmployerRegistration = '/dental-jobs/register/employer/';

export const employerRegistrationAccountDetailsPath = `account-details/`;
export const employerRegistrationPracticeDetailsPath = `practice-details/`;
export const employerRegistrationLocationDetailsPath = `location-details/`;

export const toStudentGetStarted = `/dental-jobs/register/student/`;

export const toExternalJobDetail = (jobUri) => `/dental-jobs${jobUri}/`;

export const toAboutUs = `/about-us/`;
export const toAboutOurTeam = `/about-our-team/`;
export const toContactUs = `/dental-jobs/contact-us/`;
export const toCorporateContact = `/corporate-recruiting/`;
export const toSalarySurvey = `/salary-survey/`;
export const toSalarySurveyReferral = `/salary-survey/refer-a-friend`;
export const toDentistSalaryResults = `/salary-survey/what-is-the-average-dentist-salary/`;
export const toDentalHygienistSalaryResults = `/salary-survey/what-is-the-average-dental-hygienist-salary/`;
export const toDentalAssistantSalaryResults = `/salary-survey/what-is-the-average-dental-assistant-salary/`;
export const toDentalFrontOfficeReceptionistSalaryResults = `/salary-survey/what-is-the-average-dental-receptionist-salary/`;
export const toDentalCareerGuide = `/dental-career-guide/`;
export const toWhoKit = `/hiring-toolkit/`;
export const toJobseekerToolkit = `/jobseeker-toolkit/`;
export const toDentalStudents = `/dental-students/`;
export const toMobileMarketingPage = `/mobile/`;
export const toCandidateMatchingMarketing = `/candidate-matching/`;
export const toTempMarketingPage = `/find-a-temp/`;
export const toStaffTempMarketingPage = `/dental-temp/`;

export const toFaq = `/faq/`;
export const toAdvisoryBoard = `/about-advisory-board/`;
export const toTermsOfService = `/tos/`;
export const toRefundPolicy = `/refund-policy/`;
export const toPrivacy = `/privacy/`;

export const toBrowseJobsByState = `/dental-jobs/zones/`;
export const toBrowseJobsByCity = `/dental-jobs/jobs-by-city/`;
export const toBrowseDentistJobs = `/dental-jobs/dentist-jobs/`;
export const toBrowseDentalHygienistJobs = `/dental-jobs/dental-hygienist-jobs/`;
export const toBrowseDentalAssistantJobs = `/dental-jobs/dental-assistant-jobs/`;
export const toBrowseDentalFrontOfficeJobs = `/dental-jobs/dental-front-office-jobs/`;
export const toBrowseDentalReceptionistJobs = `/dental-jobs/dental-receptionist-jobs/`;
export const toBrowseDentalOfficeManagerJobs = `/dental-jobs/dental-office-manager-jobs/`;
export const toBrowseDentalLabTechJobs = `/dental-jobs/dental-lab-tech-jobs/`;
export const toBrowseDentalSalesRepJobs = `/dental-jobs/dental-rep-jobs/`;

export const toClassifiedsList = (pageNumber?: number) =>
  `/dental-jobs/classifieds-list/${!!pageNumber ? `${pageNumber}/` : ''}`;
export const toClassifiedItemDetail = ({ url }) => `/dental-jobs${url}/`;

export const toEventsList = `/dental-jobs/events/`;
export const toEventDetail = ({ uri }) => `/dental-jobs${uri}`;
export const toEventsByCategory = (slug) =>
  `/dental-jobs/events/category/${slug}/`;

// Authenticated events paths
export const eventsPath = `events`;
export const newEventPath = `/new/`;
export const toNewEvent = `/app/events/new/`;

export const toDentalOrganizations = `/dental-jobs/links/`;
export const ceRequirementsPath = `coaching-ces`;
export const toCERequirements = `/app/coaching-ces/`;

export const toEmailPreferences = `/dental-jobs/email-preferences/`;
export const toInstructorToolkit = `/school-instructor-toolkit/`;

export const toFrontOfficeCareerGuide = `/dental-front-office/`;
export const toDentalAssistantCareerGuide = `/dental-assistant/`;
export const toDentalHygienistCareerGuide = `/dental-hygienist/`;

export const toAssessmentsMarketing = `/assessments/`;

// Wordpress content
export const toBlog = `/blog/`;
export const toBlogAllPosts = `/blog/all-posts/`;
export const toBlogAllPostsByAuthor = (slug) => `/blog/author/${slug}/`;
export const toBlogAllPostsByCategory = (slug) =>
  `/blog/${slug === 'employer' ? 'employers' : slug === 'job-seeking' ? 'job-seekers' : `category/${slug}`}/`;
export const toJobseekerBlogs = `/blog/job-seekers/`;
export const toEmployerBlogs = `/blog/employers/`;
export const toBlogAllPostsByTag = (slug) => `/blog/tag/${slug}/`;
export const toBlogPostDetail = (slug) => `/blog/${slug}/`;
export const toBlogPostURIDetail = (uri) => `/blog${uri}`;

export const toDiscMarketingPage = `/assessments/disc/`;
export const toEmotionalIntelligenceMarketingPage = `/assessments/emotional-intelligence/`;
export const toInviteToApplyMarketing = `/invite-to-apply/`;

// ---------------------
// External URLS
// ---------------------
export const dentalPostTwitterUrl = 'https://www.twitter.com/dentalpost';
export const dentalPostFacebookUrl = 'https://www.facebook.com/dentalpost/';
export const dentalPostInstagramUrl =
  'https://www.instagram.com/dentalpost.net';
export const dentalPostYouTubeUrl =
  'https://www.youtube.com/user/dentalpostnet/featured';
export const dentalPostLinkedInUrl =
  'https://www.linkedin.com/company/dentalpost/';
export const dentalPostPinterestUrl = 'https://www.pinterest.com/dentalpost/';
export const appleAppStore =
  'https://itunes.apple.com/us/app/dentalpost-mobile/id504971545';
export const googlePlayStore =
  'https://play.google.com/store/apps/details?id=net.dentalpost.mobile';
