import React from 'react';
import { Link } from 'gatsby';
import OutboundLink from '../../components/common/OutboundLink';
import { Button, Container, Col, Row } from 'reactstrap';
import {
  rootPath,
  toFaq,
  toContactUs,
  dentalPostFacebookUrl,
  dentalPostLinkedInUrl,
  dentalPostInstagramUrl,
  toPrivacy,
  toTermsOfService,
  toAboutUs,
} from '../../routes';

import { StaticImage } from 'gatsby-plugin-image';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import LeadNewsletterSubscribe from '../common/LeadNewsletterSubscribe';

const Footer = (props: { displayLeadForm: boolean }) => {
  const now = new Date();
  const currentYear = now.getFullYear();

  return (
    <footer
      className="d-print-none border-top border-light mt-auto"
      // className={classNames('d-print-none bg-white', {
      //   'border-top': isAuthenticated,
      //   'border-success': isStaff,
      //   'border-secondary': isEmployer,
      // })}
      style={{ zIndex: 2 }}
    >
      <LeadNewsletterSubscribe displayLeadForm={props.displayLeadForm} />
      <Container fluid className="py-5 px-3">
        <Row className="justify-content-center">
          <Col
            lg="4"
            className="d-flex flex-column align-items-center mx-auto"
            style={{ maxWidth: '350px' }}
          >
            <Link to={rootPath} className="d-flex justify-content-center">
              <StaticImage
                loading="lazy"
                src="../../images/dentalpost-logo-on-light-bg.png"
                placeholder="blurred"
                layout="constrained"
                alt="DentalPost Logo"
                width={148}
                height={95}
              />
            </Link>
            <p className="my-3 text-center">
              The dental industry’s premier and largest online and mobile job
              board
            </p>
            <Container fluid className="px-0">
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button
                    tag={OutboundLink}
                    color="transparent"
                    href={dentalPostFacebookUrl}
                    className="d-flex justify-content-center align-items-center p-0"
                  >
                    <FaFacebook className="fa-2x text-info" title="facebook" />
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    tag={OutboundLink}
                    color="transparent"
                    href={dentalPostLinkedInUrl}
                    target="_blank"
                    className="d-flex justify-content-center align-items-center p-0"
                  >
                    <FaLinkedin className="fa-2x text-info" title="linked in" />
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    tag={OutboundLink}
                    color="transparent"
                    href={dentalPostInstagramUrl}
                    className="d-flex justify-content-center align-items-center p-0"
                  >
                    <FaInstagram
                      className="fa-2x text-info"
                      title="instagram"
                    />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="justify-content-center my-5 fw-bold">
          <Col xs="auto">
            <Link to={toAboutUs} className="link-black">
              About Us
            </Link>
          </Col>{' '}
          <Col xs="auto" className="fa-sm px-1">
            |
          </Col>
          <Col xs="auto">
            <Link to={toFaq} className="link-black">
              FAQs
            </Link>
          </Col>
          <Col xs="auto" className="fa-sm px-1">
            |
          </Col>
          <Col xs="auto">
            <Link to={toContactUs} className="link-black">
              Contact Us
            </Link>
          </Col>
        </Row>
        <Row style={{ height: '5px' }} className="my-3">
          <Col xs="4" className="bg-primary"></Col>
          <Col xs="4" className="bg-success"></Col>
          <Col xs="4" className="bg-secondary"></Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto" className="dp-font-xs">
            <p className="fs-xs mb-0">
              &copy; Copyright {currentYear} DentalPost, Inc. All rights
              reserved.
            </p>
          </Col>
          <Col xs="auto" className="dp-font-xs">
            <Link to={toTermsOfService} className="text-start fs-xs link-black">
              Terms Of Service
            </Link>
          </Col>
          <Col xs="auto" className="dp-font-xs">
            <Link to={toPrivacy} className="text-start link-black">
              Privacy Policy
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
