import React, { useState, useEffect, useGlobal } from 'reactn';
import { PropsWithChildren } from 'react';
import agent from '../agent';
import types from '../types/services-api';

export type InvitationsContextType = {
  invitationsList: (types.JobApplicationViewType &
    types.JobInvitationViewType)[];
  updateInvitations: (
    updatedInvitation: types.JobApplicationViewType &
      types.JobInvitationViewType
  ) => void;
};

const initialInvitations: (types.JobApplicationViewType &
  types.JobInvitationViewType)[] = [];
export const InvitationsContext = React.createContext<InvitationsContextType>({
  invitationsList: initialInvitations,
  updateInvitations: (updatedInvitation) => {},
});

const InvitationsContextWrapper = (props: PropsWithChildren) => {
  const [isStaff] = useGlobal('isStaff');
  const [invitations, setInvitations] = useState<
    (types.JobApplicationViewType & types.JobInvitationViewType)[]
  >([]);

  useEffect(() => {
    if (isStaff) {
      fetchInvitations();
    }
  }, []);

  function fetchInvitations() {
    agent.Staff.invitations()
      .then((res) => {
        setInvitations(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function updateInvitations(
    updatedInvitation: types.JobApplicationViewType &
      types.JobInvitationViewType
  ) {
    const updatedInvitations = invitations.map((existingInvitation) => {
      if (
        existingInvitation.applicationId === updatedInvitation.applicationId
      ) {
        return updatedInvitation;
      } else {
        return existingInvitation;
      }
    });
    setInvitations(updatedInvitations);
  }

  //for DevTools to display context name
  InvitationsContext.displayName = 'Invitations Context';

  return (
    <InvitationsContext.Provider
      value={{
        invitationsList: invitations,
        updateInvitations,
      }}
    >
      {props.children}
    </InvitationsContext.Provider>
  );
};

export default InvitationsContextWrapper;
