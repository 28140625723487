import React, { useState, useEffect, useContext, useGlobal } from 'reactn';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Button } from 'reactstrap';
import { FaTag, FaTimes } from 'react-icons/fa';
import { isAuthenticatedToken } from '../../utils/authentication';
import agent from '../../agent';
import { Link } from '@reach/router';
import dayjs from 'dayjs';
import useLocalStorageState from 'use-local-storage-state';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UserContext } from '../../context/UserContext';
import analytics, {
  ENGAGEMENT_CATEGORY,
  PROMO_CLICK_ACTION,
} from '../../analytics';

const BUILD_ID = process.env.GATSBY_BUILD_ID || 'dev';

const MarketingPromotion = (props: { bannerHeight: number }) => {
  const data = useStaticQuery<{
    allContentfulPromotion: {
      edges: {
        node: MarketingPromotionType;
      }[];
    };
  }>(contentQuery);
  const windowDims = useWindowDimensions();
  const [viewedPromotions, setViewedPromotions] = useLocalStorageState<{
    [key: string]: string[];
  }>('viewedPromotions', { defaultValue: { unauthenticated: [] } });
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const context = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [promotion, setPromotion] = useState<
    | {
        node: MarketingPromotionType;
      }
    | undefined
  >(undefined);
  const [userId, setUserId] = useState('unauthenticated');

  useEffect(() => {
    if (
      isAuthenticatedToken() &&
      'user' in context &&
      !!context.user &&
      context.user.username !== userId
    ) {
      setUserId(context.user.username);
    } else if (!isAuthenticatedToken()) {
      setUserId('unauthenticated');
    }
  }, [context, isAuthenticated]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const contentfulPromos = data.allContentfulPromotion.edges.filter(
        (edge) => edge.node.buildEnvironment.includes(BUILD_ID)
      );
      checkDiscount(contentfulPromos);
    }
  }, [userId]);

  useEffect(() => {
    if (
      !!promotion &&
      !viewedPromotions[userId]?.includes(promotion.node.discountCode)
    ) {
      setIsOpen(true);
    }
  }, [promotion, viewedPromotions]);

  function checkDiscount(contentfulPromos: { node: MarketingPromotionType }[]) {
    const api = isAuthenticated ? agent.Jobs.catalog : agent.Jobs.publicCatalog;
    contentfulPromos.forEach((promo) => {
      api(promo.node.discountCode)
        .then((res) => {
          const foundDiscount = res.data.discounts.find(
            (discount) => discount.discountCode === promo.node.discountCode
          );
          if (foundDiscount) {
            setPromotion(promo);
          }
        })
        .catch((err) => console.log(err));
    });
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  function trackButtonClick(discountCode: string) {
    analytics.Events.trackEvent({
      actionType: PROMO_CLICK_ACTION,
      category: ENGAGEMENT_CATEGORY,
      label: discountCode,
    });
  }

  function updateLocalStorage() {
    if (
      !!promotion &&
      !viewedPromotions[userId]?.includes(promotion.node.discountCode)
    ) {
      const previousPromotions = viewedPromotions[userId] ?? [];
      const updatedPromotions: { [key: string]: string[] } = {
        ...viewedPromotions,
        [`${userId}`]: [...previousPromotions, promotion?.node.discountCode],
      };
      console.log(updatedPromotions);

      setViewedPromotions(updatedPromotions);
    }
  }

  if (!!promotion && !!windowDims && windowDims?.width > 400) {
    return (
      <div
        style={{
          position: isOpen ? 'absolute' : undefined,
          top: `calc(85px + ${props.bannerHeight}px)`,
          right: '1rem',
          maxWidth: '500px',
          transition: 'height 2s linear',
        }}
      >
        <div className="d-flex align-items-center bg-white rounded-start shadow w-100 h-100">
          <Button
            tag="div"
            onClick={toggle}
            className={`${isOpen ? 'rounded-start rounded-end-0' : ''} col-auto align-self-stretch px-3 d-flex align-items-center bg-success border border-success`}
          >
            <FaTag
              className={`text-white ${isOpen ? 'fa-2x' : 'dp-font-lg'}`}
            />
          </Button>
          {isOpen && (
            <div className="p-3 col flex-grow-1 position-relative">
              <div className="d-flex flex-wrap">
                <div className="col-auto px-2">
                  <p className="fw-bold text-lg">{promotion.node.heading}</p>
                  <small>
                    Promo Code:{' '}
                    <span className="fw-bold text-muted">
                      {promotion.node.discountCode}
                    </span>{' '}
                    {!!promotion.node.expirationDate && (
                      <>
                        <br /> <span>Offer Expires:</span>
                        <span className="text-muted fw-bold">
                          {' '}
                          {dayjs(promotion.node.expirationDate).format(
                            'MMM DD, YYYY'
                          )}
                        </span>
                      </>
                    )}
                  </small>
                </div>
                <div className="col-auto d-flex ms-auto align-items-end px-3 pt-3">
                  <Button
                    tag={Link}
                    to={promotion.node.ctaLink}
                    color="primary"
                    onClick={() => {
                      updateLocalStorage();
                      toggle();
                      trackButtonClick(promotion.node.discountCode);
                      navigate(
                        `${promotion.node.ctaLink}?discountCode=${promotion.node.discountCode}`
                      );
                    }}
                  >
                    {promotion.node.ctaText}
                  </Button>
                </div>
              </div>
              <Button
                color="transparent"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => {
                  updateLocalStorage();
                  toggle();
                }}
              >
                <FaTimes className="fa-lg text-light" />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MarketingPromotion;

const contentQuery = graphql`
  query {
    allContentfulPromotion {
      edges {
        node {
          buildEnvironment
          ctaLink
          ctaText
          discountCode
          heading
          id
          expirationDate
        }
      }
    }
  }
`;

export type MarketingPromotionType = {
  buildEnvironment: string;
  ctaLink: string;
  ctaText: string;
  discountCode: string;
  heading: string;
  id: string;
  expirationDate?: string;
};
