import React, {
  useState,
  useEffect,
  PropsWithChildren,
  SetStateAction,
} from 'react';
import types, {
  JobOwnerSummaryViewType,
  ProductOrderViewType,
} from '../types/services-api';
import agent from '../agent';
import { navigate } from 'gatsby';
import { toJobPostingEdit, toJobSlotEdit } from '../routes';
import { getPosition } from '../utils/displayHelper';
import dayjs from 'dayjs';
import _orderBy from 'lodash/orderBy';
import { Many } from 'lodash';
import { EditJobType } from '../components/job-postings/JobPostingForm';

export const initialFilters: JobPostingFiltersType = {
  keyword: '',
  startDate: '',
  endDate: '',
  location: '',
  status: '',
};

export const initialSort: JobPostingSortType = {
  formattedList: [],
  keys: ['id'],
  order: 'desc',
};

export type JobPostingContextType = {
  activeJobs?: types.JobOwnerSummaryViewType[];
  drafts?: types.JobOwnerSummaryViewType[];
  expiredPosts?: types.JobOwnerSummaryViewType[];
  closedActivePosts?: types.JobOwnerSummaryViewType[];
  credits?: types.JobOwnerSummaryViewType[];
  filteredPosts: types.JobOwnerSummaryViewType[];
  sortedPosts?: types.JobOwnerSummaryViewType[] | undefined;
  sort: JobPostingSortType;
  sortList: (newSort: JobPostingSortType) => void;
  filters: JobPostingFiltersType;
  setFilters: React.Dispatch<SetStateAction<JobPostingFiltersType>>;
  addressSet: {
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  }[];
  jobPostingsList: types.JobOwnerSummaryViewType[] | undefined;
  subscription: ProductOrderViewType | null | undefined;
  unassignedSlots: types.JobSlotType[];
  jobSlotList: types.JobSlotType[];
  refreshJobLists: (navigateToLatest?: boolean) => void;
  refreshSubscription: (navigateToLatest?: boolean) => void;
  updateSubscription: (values: Partial<ProductOrderViewType>) => void;
  subscriptionDraft?: EditJobType;
  updateSubscriptionDraft: (newDraft?: EditJobType) => void;
};

export const JobPostingContext = React.createContext<JobPostingContextType>({
  activeJobs: [],
  drafts: [],
  expiredPosts: [],
  closedActivePosts: [],
  credits: [],
  filteredPosts: [],
  sortedPosts: undefined,
  sort: initialSort,
  sortList: () => {},
  filters: initialFilters,
  setFilters: () => {},
  addressSet: [],
  jobPostingsList: undefined,
  subscription: null,
  unassignedSlots: [],
  jobSlotList: [],
  refreshJobLists: () => {},
  refreshSubscription: () => {},
  updateSubscription: () => {},
  subscriptionDraft: undefined,
  updateSubscriptionDraft: () => {},
});

export type ResumeSearchFiltersType = {
  keyword?: string;
  type?: string;
  evaluation?: string;
  photo?: boolean;
  disc?: boolean;
  values?: boolean;
  skills?: boolean;
  culture?: boolean;
  dow: string;
  radius: string;
  limit: number;
  offset: number;
};

export interface ResumeSearchParametersType extends ResumeSearchFiltersType {
  position: string;
  zip: string;
}

export type JobPostingSortType = {
  formattedList: (JobOwnerSummaryViewType & { customColumn?: any })[];
  keys: string[];
  order: Many<boolean | 'desc' | 'asc'> | undefined;
};

export type JobPostingFiltersType = {
  keyword: string;
  startDate: string;
  endDate: string;
  location: string;
  status: string;
  isSubscription?: boolean;
};

export const DEFAULT_RESUME_SEARCH_FILTERS: ResumeSearchFiltersType = {
  dow: '0000000',
  radius: 'NONE',
  limit: 25,
  offset: 0,
};

export function formatAddressKey(obj) {
  return `${obj.address} ${obj.address2}, ${obj.city} ${obj.state}, ${obj.zip}`;
}

const JobPostingContextProvider = (props: PropsWithChildren) => {
  const [summarizedJobHistory, setSummarizedJobHistory] = useState<
    | {
        expiredPosts: JobOwnerSummaryViewType[];
        closedActivePosts: JobOwnerSummaryViewType[];
        activePosts: JobOwnerSummaryViewType[];
        draftPosts: JobOwnerSummaryViewType[];
        creditPosts: JobOwnerSummaryViewType[];
      }
    | undefined
  >(undefined);
  const [sort, setSort] = useState<JobPostingSortType>(initialSort);
  const [sortedPosts, setSortedPosts] = useState<
    JobOwnerSummaryViewType[] | undefined
  >(undefined);
  const [filters, setFilters] = useState<JobPostingFiltersType>(initialFilters);
  const [filteredPosts, setFilteredPosts] = useState<JobOwnerSummaryViewType[]>(
    []
  );
  const [addressSet, setAddressSet] = useState<
    {
      address: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    }[]
  >([]);
  const [jobPostingsList, setJobPostingsList] = useState<
    JobOwnerSummaryViewType[] | undefined
  >(undefined);
  const [jobSlotList, setJobSlotList] = useState<types.JobSlotType[]>([]);
  const [subscription, setSubscription] = useState<
    ProductOrderViewType | null | undefined
  >(undefined);
  const [unassignedSlots, setUnassignedSlots] = useState<types.JobSlotType[]>(
    []
  );
  const [subscriptionDraft, setSubscriptionDraft] = useState<
    EditJobType | undefined
  >(undefined);
  useEffect(() => {
    fetchJobPostings();
    fetchJobSlots();
  }, []);

  useEffect(() => {
    if (
      !!jobPostingsList &&
      !!summarizedJobHistory &&
      jobPostingsList.length > 0
    ) {
      if (filters !== initialFilters) {
        const updatedFilteredList = jobPostingsList.filter((job) => {
          const searchTextLowercase = filters.keyword.toLowerCase();
          const keywordFilter =
            `${job.id}`.includes(searchTextLowercase) ||
            (job.city &&
              job.city.toLowerCase().includes(searchTextLowercase)) ||
            (job.state &&
              job.state.toLowerCase().includes(searchTextLowercase)) ||
            (job.zip && job.zip.includes(searchTextLowercase)) ||
            getPosition(job.position)
              .toLowerCase()
              .includes(searchTextLowercase) ||
            (job.name &&
              job.name.toLowerCase().includes(searchTextLowercase)) ||
            (job.description &&
              job.description.toLowerCase().includes(searchTextLowercase)) ||
            (job.isPlanned && 'temp'.includes(searchTextLowercase)) ||
            (job.isBasic && 'basic'.includes(searchTextLowercase)) ||
            (!job.isBasic && 'premium'.includes(searchTextLowercase)) ||
            (job.isSubscription &&
              'subscription'.includes(searchTextLowercase));

          const dateFilter =
            !!filters.startDate && !!filters.endDate
              ? dayjs(job.postedDate).isAfter(dayjs(filters.startDate)) &&
                dayjs(job.postedDate).isBefore(dayjs(filters.endDate))
              : !!filters.startDate
                ? dayjs(job.postedDate).isAfter(dayjs(filters.startDate))
                : !!filters.endDate
                  ? dayjs(job.postedDate).isBefore(dayjs(filters.endDate))
                  : true;
          const locationFilter = !!filters.location
            ? `${formatAddressKey(job)}` === filters.location
            : true;
          const statusFilter = !!filters.status
            ? filterByStatus(job, filters.status)
            : true;
          const subscriptionFilter = filters.isSubscription
            ? job.isSubscription
            : true;
          return (
            keywordFilter &&
            dateFilter &&
            locationFilter &&
            statusFilter &&
            subscriptionFilter
          );
        });
        setFilteredPosts(updatedFilteredList);
        setSortedPosts(updatedFilteredList);
        sortList({ ...initialSort, formattedList: updatedFilteredList });
      } else {
        setFilteredPosts(jobPostingsList);
        setSortedPosts(jobPostingsList);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (sort.formattedList.length > 0) {
      const updatedSort: (types.JobOwnerSummaryViewType & {
        customColumn?: any;
      })[] = _orderBy(sort.formattedList, sort.keys, sort.order);
      setSortedPosts(updatedSort);
    }
  }, [sort]);

  function filterByStatus(job: JobOwnerSummaryViewType, status: string) {
    switch (status) {
      case 'Draft':
        return job.status === 'DRAFT' && !!job.position;
      case 'Filled':
        return job.status === 'BOOKED';
      case 'Closed':
        return (
          job.status === 'CANCELED' ||
          (job.isUnslotted && job.status !== 'BOOKED')
        );
      case 'Expired':
        return job.isExpired && job.status === 'ACTIVE';
      case 'Active':
        return job.isActive;
      default:
        return false;
    }
  }

  function sortList(props: { formattedList; keys; order }) {
    setSort(props);
  }

  function fetchJobPostings(navigateToLatest?: boolean) {
    agent.Employer.jobs()
      .then((res) => {
        const nonCreditJobs = res.data.filter((job) => !!job.position);
        const addresses = [
          ...new Map(
            nonCreditJobs.map((obj) => [
              `${formatAddressKey(obj)}`,
              {
                address: obj.address,
                address2: obj.address2,
                city: obj.city,
                state: obj.state,
                zip: obj.zip,
              },
            ])
          ).values(),
        ];
        setAddressSet(addresses);
        setSortedPosts(nonCreditJobs);
        const categorizedPosts = res.data.reduce<{
          expiredPosts: JobOwnerSummaryViewType[];
          closedActivePosts: JobOwnerSummaryViewType[];
          activePosts: JobOwnerSummaryViewType[];
          draftPosts: JobOwnerSummaryViewType[];
          creditPosts: JobOwnerSummaryViewType[];
        }>(
          (obj, item) => {
            const categorizedItems = {
              expiredPosts:
                item.isExpired || item.isUnslotted
                  ? [...obj['expiredPosts'], item]
                  : obj['expiredPosts'],
              closedActivePosts:
                !item.isExpired &&
                !item.isUnslotted &&
                (item.status === 'BOOKED' || item.status === 'CANCELED')
                  ? [...obj['closedActivePosts'], item]
                  : obj['closedActivePosts'],
              activePosts:
                !item.isExpired && !item.isUnslotted && item.status === 'ACTIVE'
                  ? [...obj['activePosts'], item]
                  : obj['activePosts'],
              draftPosts:
                item.status === 'DRAFT' && !!item.position
                  ? [...obj['draftPosts'], item]
                  : obj['draftPosts'],
              creditPosts:
                item.status === 'DRAFT' && !item.position
                  ? [...obj['creditPosts'], item]
                  : obj['creditPosts'],
            };
            return { ...categorizedItems };
          },
          {
            expiredPosts: [],
            closedActivePosts: [],
            activePosts: [],
            draftPosts: [],
            creditPosts: [],
          }
        );
        setSummarizedJobHistory(categorizedPosts);
        setJobPostingsList(nonCreditJobs);
        setFilteredPosts(nonCreditJobs);
        if (navigateToLatest) {
          navigate(toJobPostingEdit(categorizedPosts.creditPosts[0].id));
        }
      })
      .catch((err) => {
        // TODO: show a global, floating Alert?
        console.warn(err.message);
      });
  }

  function fetchJobSlots(navigateToLatest?: boolean) {
    agent.Employer.jobSlots()
      .then((res) => {
        const response = res.data;
        const unassigned = response
          .filter((slot) => slot.activeJobId === 0)
          .sort((a, b) => (a.slotId > b.slotId ? 1 : -1));
        setJobSlotList(response);
        if (navigateToLatest) {
          navigate(toJobSlotEdit(response[0].slotId), {
            state: { selectedAction: 'CREATE-NEW' },
          });
        }
        if (response.length > 0) {
          agent.Employer.subscription(response[0].orderId)
            .then((subRes) => {
              setSubscription(subRes.data);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          setSubscription(null);
        }
        setUnassignedSlots(unassigned);
      })
      .catch((err) => {
        // TODO: show a global, floating Alert?
        console.warn(err.message);
      });
  }

  function refreshJobLists(navigateToLatest?: boolean) {
    fetchJobPostings(navigateToLatest);
  }

  function refreshSubscription(navigateToLatest?: boolean) {
    fetchJobSlots(navigateToLatest);
  }

  function updateSubscription(values: Partial<ProductOrderViewType>) {
    if (!!subscription) {
      setSubscription({ ...subscription, ...values });
    }
  }

  function updateSubscriptionDraft(newDraft?: EditJobType) {
    setSubscriptionDraft(newDraft);
  }

  JobPostingContext.displayName = 'Job Posting Context';

  return (
    <JobPostingContext.Provider
      value={{
        activeJobs: summarizedJobHistory?.activePosts,
        drafts: summarizedJobHistory?.draftPosts,
        expiredPosts: summarizedJobHistory?.expiredPosts,
        closedActivePosts: summarizedJobHistory?.closedActivePosts,
        credits: summarizedJobHistory?.creditPosts,
        sortedPosts,
        sort,
        sortList,
        filteredPosts,
        filters,
        setFilters,
        addressSet,
        jobPostingsList,
        unassignedSlots,
        jobSlotList,
        refreshJobLists,
        refreshSubscription,
        subscription,
        updateSubscription,
        subscriptionDraft,
        updateSubscriptionDraft,
      }}
    >
      {props.children}
    </JobPostingContext.Provider>
  );
};

export default JobPostingContextProvider;
