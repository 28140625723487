import { Link } from 'gatsby';
import { default as React } from 'react';
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
} from 'react-instantsearch-dom';

const HitCount = connectStateResults(({ searchResults, indexTitle }) => {
  const hitCount = searchResults && searchResults.nbHits;
  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} Matching {indexTitle}
    </div>
  ) : null;
});

const NoResults = connectStateResults(({ allSearchResults }) => {
  const hasResults =
    allSearchResults &&
    Object.values(allSearchResults).some((results) => results.nbHits > 0);
  return !hasResults ? (
    <div>
      <span>No results found</span>
    </div>
  ) : (
    <></>
  );
});

const SearchResult = ({ indices, className, goToLink }) => {
  const PageHit = ({ hit }) => (
    <div>
      <Link
        to={hit.slug}
        onClick={(e) => {
          e.preventDefault();
          goToLink(hit.slug);
        }}
      >
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Link>
    </div>
  );

  const HitsInIndex = ({ index }) => (
    <Index indexName={index.name}>
      <HitCount indexTitle={index.title} />
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  );

  return (
    <div className={className}>
      <NoResults />
      {indices.map((index) => (
        <HitsInIndex index={index} key={index.name} />
      ))}
    </div>
  );
};

export default SearchResult;
