const BUILD_ID = process.env.GATSBY_BUILD_ID || 'dev';

export const log = (message, data) => {
  if (BUILD_ID !== 'production') {
    if (data) {
    console.debug(message, data);
    }
    else {
      console.debug(message);}
  }
}

