import React, { useState, createRef } from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { FaPencilAlt, FaTrash, FaUndo, FaCamera, FaPlus } from 'react-icons/fa';
import AvatarEditor from 'react-avatar-editor';

const FILE_SIZE_MB_MAX = 5; // megabytes

const UploadPhotoModal = (props: {
  hasPhoto: boolean;
  photoUrl: string;
  resourceName: string;
  imageRadius?: number;
  onAdd?: (photoFile: File | Blob, description?: string) => void;
  onUpdate?: (photoFile: File | Blob, description?: string) => void;
  onDelete?: () => void;
  photoErrorMessage: string;
}) => {
  const photoRef = createRef<AvatarEditor>();
  const [modalOpen, setModalOpen] = useState(false);
  const [photoFile, setPhotoFile] = useState<Blob | null>(null);
  const [imageURI, setImageURI] = useState(props.photoUrl);
  const [zoomScale, setZoomScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [editModeOn, setEditModeOn] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function addPhoto() {
    const photoFile = document.getElementById('photoFile');
    if (!!photoFile) {
      photoFile.click();
    }
  }

  function onConfirm(selectedFile: Blob) {
    if (props.hasPhoto && !!props.onUpdate) {
      props.onUpdate(selectedFile);
    } else if (!!props.onAdd) {
      props.onAdd(selectedFile);
    }
  }

  function setSelectedPhoto(event) {
    if (event.target && event.target.files && event.target.files[0]) {
      const selectedFile: File = event.target.files[0];

      if (Number(getFileSizeMb(selectedFile.size)) <= FILE_SIZE_MB_MAX) {
        setFileError(false);
        let reader = new FileReader();
        reader.onload = function (event) {
          const result =
            !!event.target && !!event.target.result
              ? event.target.result
              : undefined;
          if (typeof result === 'string') {
            setImageURI(result);
          }
        };
        reader.readAsDataURL(event.target.files[0]);
        setPhotoFile(selectedFile);
        onConfirm(selectedFile);
      } else {
        setFileError(true);
      }
    } else {
      console.warn('No file selected');
    }
  }

  function editPhoto() {
    fetch(imageURI)
      .then((res) => res.blob())
      .then((blob) => {
        setPhotoFile(blob);
        setEditModeOn(true);
      });
  }

  function getFileSizeMb(sizeBytes: number) {
    const sizeMb = sizeBytes / 1000 / 1000;
    return sizeMb.toFixed(1);
  }

  function confirmPhoto(event) {
    event.preventDefault();

    if (!!photoRef && !!photoRef.current) {
      const canvas = photoRef.current;
      const dataUrl = canvas.getImage().toDataURL();
      fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          onConfirm(blob);
          toggleModal();
        });
    }
  }

  function removePhoto() {
    setDeleteModalIsOpen(false);
    setImageURI(props.photoUrl);
    setPhotoFile(null);
    if (!!props.onDelete) {
      props.onDelete();
    }
  }

  function reset() {
    setPhotoFile(null);
    setEditModeOn(false);
    setImageURI(props.photoUrl);
  }

  return (
    <>
      <Button
        color="primary"
        outline
        onClick={toggleModal}
        className="position-relative rounded-circle d-flex justify-content-center align-items-center"
        style={{ height: '40px', width: '40px' }}
      >
        {props.hasPhoto ? (
          <FaPencilAlt title="Edit Photo" />
        ) : (
          <FaPlus title="Add a Photo" />
        )}
      </Button>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <Form id="photo-upload-form">
          <ModalHeader toggle={toggleModal}>
            Upload/Edit {props.resourceName} Photo
          </ModalHeader>
          <ModalBody>
            {!editModeOn && (
              <div className="mx-auto text-center">
                <img
                  src={imageURI}
                  alt={props.resourceName}
                  className="img-fluid mx-auto"
                  style={{
                    backgroundColor: 'black',
                    width: '300px',
                    height: 'auto',
                  }}
                />
              </div>
            )}
            {editModeOn && !!photoFile && (
              <div className="text-center">
                <AvatarEditor
                  ref={photoRef}
                  image={imageURI}
                  // width={}
                  borderRadius={
                    props.imageRadius ? props.imageRadius : undefined
                  }
                  color={[0, 0, 0, 0.8]} // RGBA
                  scale={Number(zoomScale)}
                  rotate={rotation}
                  crossOrigin="anonymous"
                  className="img-fluid"
                />
                <p>
                  <em>Drag to reposition photo </em>
                </p>
              </div>
            )}
            {editModeOn && !!photoFile && (
              <Row className="d-flex align-items-center justify-content-around">
                <Col
                  xs="auto"
                  className="d-flex flex-column align-items-center mb-3"
                >
                  Zoom:
                  <Input
                    id="zoom"
                    type="range"
                    min={0.1}
                    max={2}
                    step={0.1}
                    defaultValue={1}
                    style={{ width: '250px' }}
                    onChange={(e) => setZoomScale(Number(e.target.value))}
                    capture
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    color="link"
                    type="button"
                    className="d-flex flex-column align-items-center"
                    onClick={() => {
                      setRotation(rotation - 90);
                    }}
                  >
                    <FaUndo />
                    Rotate
                  </Button>
                </Col>
              </Row>
            )}
            <Alert color="danger" isOpen={!!fileError} className="mt-3">
              The photo you have chosen is a very large file. Please choose a
              file that is less than {FILE_SIZE_MB_MAX} MB.
            </Alert>
            <FormGroup className="d-none">
              <Input
                type="file"
                name="photoFile"
                id="photoFile"
                data-testid="photoFile"
                onChange={setSelectedPhoto}
                accept="image/*"
              ></Input>
              <FormText color="muted">
                Select a photo from your computer or device.
              </FormText>
            </FormGroup>
          </ModalBody>
          {editModeOn ? (
            <ModalFooter>
              <Button
                type="button"
                onClick={reset}
                color="light"
                className="me-3"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={confirmPhoto}
                color="primary"
                disabled={
                  !photoFile ||
                  ('size' in photoFile &&
                    Number(getFileSizeMb(photoFile.size)) > FILE_SIZE_MB_MAX)
                }
              >
                Save
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Row className="justify-content-around w-100">
                <Col xs="auto" className="px-0">
                  <Button
                    color="link"
                    className="d-flex flex-column align-items-center"
                    onClick={editPhoto}
                  >
                    <FaPencilAlt /> Edit
                  </Button>
                </Col>
                <Col xs="auto" className="px-0">
                  <Button
                    color="link"
                    className="d-flex flex-column align-items-center"
                    onClick={addPhoto}
                  >
                    <FaCamera /> Choose Photo
                  </Button>
                </Col>
                {props.hasPhoto && !!props.onDelete && (
                  <Col xs="auto" className="px-0">
                    <Button
                      onClick={() => setDeleteModalIsOpen(true)}
                      color="link"
                      className="d-flex flex-column align-items-center"
                    >
                      <FaTrash /> Remove
                    </Button>
                  </Col>
                )}
              </Row>
            </ModalFooter>
          )}
        </Form>
      </Modal>

      <Modal isOpen={deleteModalIsOpen}>
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this photo?</p>
          <p>This action cannot be undone.</p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={() => setDeleteModalIsOpen(false)} color="link">
            Cancel
          </Button>
          <Button onClick={removePhoto} color="primary">
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadPhotoModal;
