import _get from 'lodash/get';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { setGlobal } from 'reactn';
import { GlobalStateType } from '../context/GlobalContext';

export type UserDataType = {
  userId: number;
  name: string;
  email: string;
};
export function userDataAvailable(object: any): object is UserDataType {
  return 'userId' in object;
}

function localStorageAvailable(object: any): object is Window {
  return 'localStorage' in object;
}

const TOKEN_IDENTIFIER = 'dentalpost_auth_token';
const TOKEN_USER_DATA = 'dentalpost_token_user_data';
const CURRENT_USER_DATA = 'dentalpost_current_user_data';

const setAuthToken = (token) => {
  try {
    if (token) {
      const decodedToken = jwtDecode(token);

      axios.defaults.headers.common['Authorization'] = token;
      window.localStorage.setItem(TOKEN_IDENTIFIER, token);
      window.localStorage.setItem(
        TOKEN_USER_DATA,
        JSON.stringify(decodedToken)
      );
      const role = _get(decodedToken, 'role');
      const authState = {
        isAuthenticated: isAuthenticatedToken(),
        isEmployer: isEmployerRole(),
        isStaff: isStaffRole(),
      };
      setGlobal<GlobalStateType>({
        ...authState,
      });

      return true;
    }
  } catch (err) {
    console.error('Could not save token data', err);
  }

  return false;
};

const getAuthToken = () =>
  typeof window !== 'undefined' && localStorageAvailable(window)
    ? window.localStorage.getItem(TOKEN_IDENTIFIER)
    : null;

const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_IDENTIFIER);
  window.localStorage.removeItem(TOKEN_USER_DATA);
  const unauthState = {
    isAuthenticated: false,
    isEmployer: false,
    isStaff: false,
  };
  setGlobal<GlobalStateType>({
    ...unauthState,
  });
};

const isAuthenticatedToken = () => getAuthToken() !== null;

const userRole = () => {
  if (typeof window !== 'undefined' && localStorageAvailable(window)) {
    const tokenUserData = window.localStorage.getItem(TOKEN_USER_DATA);
    if (!!tokenUserData) {
      const userData = JSON.parse(tokenUserData);
      return _get(userData, 'role', null);
    }
  } else {
    return null;
  }
};

const username = () => {
  const tokenUserData = window.localStorage.getItem(TOKEN_USER_DATA);
  if (!!tokenUserData) {
    const userData = JSON.parse(tokenUserData);
    return _get(userData, 'sub', null);
  }
};

const EMPLOYER_ROLE = 'employer';
const STAFF_ROLE = 'staff';
const isEmployerRole = () => userRole() === EMPLOYER_ROLE;
const isStaffRole = () => userRole() === STAFF_ROLE;

const setUserData = (userData: UserDataType) => {
  try {
    if (userData) {
      // save a few other key pieces of user info
      window.localStorage.setItem(CURRENT_USER_DATA, JSON.stringify(userData));
    }
  } catch (err) {
    console.error('Could not save user data');
  }
};

const getUserData = () => {
  const currentUserData = window.localStorage.getItem(CURRENT_USER_DATA);
  if (!!currentUserData) {
    const userData: UserDataType = JSON.parse(currentUserData);
    return userData;
  }
};

const destroyUserData = () => {
  window.localStorage.removeItem(CURRENT_USER_DATA);
};

const encodedUsername = (anyUsername?: string) => {
  return encodeURIComponent(anyUsername || username());
};

const decodedUsername = (anyUsername?: string) => {
  return decodeURIComponent(anyUsername || username());
};

export {
  destroyToken,
  encodedUsername,
  decodedUsername,
  getAuthToken,
  isAuthenticatedToken,
  isEmployerRole,
  isStaffRole,
  setAuthToken,
  setUserData,
  getUserData,
  destroyUserData,
  userRole,
  username,
};
