import React, { useState, useEffect, useGlobal } from 'reactn';
import { PropsWithChildren, useContext } from 'react';
import agent from '../agent';
import types from '../types/services-api';
import analytics, {
  PROFILE_COMPLETION_ACTION,
  ENGAGEMENT_CATEGORY,
  ABLE_TO_APPLY_LABEL,
} from '../analytics';
import ApplicationsContext from './ApplicationsContext';
import JobPostingContext from './JobPostingContext';
import { UserContext, UserContextType } from './UserContext';
import InvitationsContext from './InvitationsContext';

export type ProfileType =
  | types.EmployerProfileViewType
  | types.StaffResumeViewType;

export function isProfileType(object: any): object is ProfileType {
  return 'public' in object;
}

export function isEmployerProfileType(
  object: any
): object is types.EmployerProfileViewType {
  return !!object && 'about' in object;
}

export function isStaffProfileType(
  object: any
): object is types.StaffResumeViewType {
  return 'certs' in object;
}

type SharedProfileContextType = {
  updateProfile: (updatedProfileValues: {}) => void;
  upsertProfileItem: (itemType: string, item: { id: number }) => void;
  removeProfileItem: (itemType: string, itemId: number) => void;
};

export interface StaffProfileContextType extends SharedProfileContextType {
  profile: types.StaffResumeViewType;
}

export interface EmployerProfileContextType extends SharedProfileContextType {
  profile: types.EmployerProfileViewType;
}

export type ProfileContextType =
  | StaffProfileContextType
  | EmployerProfileContextType;

export type OfficesContextType = {
  offices: types.OfficeProfileViewType[];
  primaryOffice: types.OfficeProfileViewType | null;
  updateOffices: (updatedOffices: types.OfficeProfileViewType[]) => void;
  updatePrimaryOffice: (officeId: number) => void;
  upsertOffice: (updatedOffice: types.OfficeProfileViewType) => void;
  removeOffice: (officeId: number) => void;
  purchaseHistoryList: types.PaymentReceiptType[];
  fetchReceipts: () => void;
};

export const ProfileContext = React.createContext<ProfileContextType | {}>({});

export const OfficesContext = React.createContext<OfficesContextType>({
  offices: [],
  primaryOffice: null,
  updateOffices: () => {},
  updatePrimaryOffice: () => {},
  upsertOffice: () => {},
  removeOffice: () => {},
  purchaseHistoryList: [],
  fetchReceipts: () => {},
});

const ProfileContextWrapper = (props: PropsWithChildren) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const [isEmployer] = useGlobal('isEmployer');
  const [isStaff] = useGlobal('isStaff');
  const [profile, setProfile] = useState<ProfileType | {}>({});
  const [purchaseHistoryList, setPurchaseHistoryList] = useState<
    types.PaymentReceiptType[]
  >([]);

  useEffect(() => {
    if (isEmployer) {
      fetchEmployerProfile();
      fetchOffices();
      fetchReceipts();
    } else if (isStaff) {
      fetchStaffProfile();
    }
  }, [isAuthenticated]);

  function fetchEmployerProfile() {
    agent.Employer.profile()
      .then((res) => {
        setProfile({ ...res.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function fetchReceipts() {
    agent.Employer.receipts()
      .then((res) => {
        setPurchaseHistoryList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function fetchStaffProfile() {
    agent.Staff.profile()
      .then((res) => {
        setProfile({
          ...res.data,
        });
      })
      .catch((err) => {
        if (err) {
          console.error(err.message);
        }
      });
  }

  function updateProfile(
    updatedProfileValues: Partial<types.StaffResumeViewType>
  ) {
    if (
      isStaffProfileType(profile) &&
      !updatedProfileValues.isGoodToApply &&
      !!updatedProfileValues.intro &&
      !!updatedProfileValues.skills &&
      updatedProfileValues.skills.length >= 5
    ) {
      agent.Staff.profile().then((res) => {
        setProfile(res.data);
        analytics.Events.trackEvent({
          actionType: PROFILE_COMPLETION_ACTION,
          category: ENGAGEMENT_CATEGORY,
          label: ABLE_TO_APPLY_LABEL,
        });
      });
    } else {
      setProfile({ ...profile, ...updatedProfileValues });
    }
  }

  function upsertProfileItem(itemType: string, item: { id: number }) {
    let updatedList;
    const itemExists = profile[itemType].find((i) => i.id === item.id);
    if (itemExists) {
      updatedList = profile[itemType].map((i) => (i.id === item.id ? item : i));
    } else {
      updatedList = [...profile[itemType], item];
    }
    updateProfile({
      [itemType]: updatedList,
    });
    return Promise.resolve(updatedList);
  }

  function removeProfileItem(itemType: string, itemId: number) {
    const updatedList = [...profile[itemType]].filter((i) => i.id !== itemId);
    updateProfile({ [itemType]: updatedList });
  }

  // ****** Offices ********
  const { setDefaultMapCenter } = useContext(UserContext) as UserContextType;
  const [offices, setOffices] = useState<types.OfficeProfileViewType[]>([]);
  const [primaryOffice, setPrimaryOffice] =
    useState<types.OfficeProfileViewType | null>(null);

  useEffect(() => {
    if (!!primaryOffice) {
      setDefaultMapCenter({
        lat: Number(primaryOffice.lat),
        lng: Number(primaryOffice.lng),
      });
    }
  }, [primaryOffice]);

  function fetchOffices() {
    agent.Employer.offices()
      .then((res) => {
        setOffices(res.data);
        const primary = res.data.find((office) => office.isPrimary);
        if (!!primary) {
          setPrimaryOffice(primary);
        } else {
          setPrimaryOffice(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setOffices([]);
      });
  }

  function updateOffices(updatedOffices: types.OfficeProfileViewType[]) {
    setOffices(updatedOffices);
  }

  function upsertOffice(office: types.OfficeProfileViewType) {
    let updatedList: types.OfficeProfileViewType[];
    const itemExists = offices.some((i) => i.id === office.id);
    if (itemExists) {
      updatedList = offices.map((i: types.OfficeProfileViewType) =>
        i.id === office.id ? office : i
      );
    } else {
      updatedList = [...offices, office];
    }

    if (office.isPrimary) {
      setPrimaryOffice(office);
    }
    updateOffices(updatedList);
  }

  function updatePrimaryOffice(officeId: number) {
    agent.Employer.updatePrimaryOffice({ officeId })
      .then((res) => {
        updateOffices([
          res.data,
          ...offices.filter((office) => office.id !== res.data.id),
        ]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function removeOffice(officeId: number) {
    const updatedList: types.OfficeProfileViewType[] = offices.filter(
      (i) => i.id !== officeId
    );
    updateOffices(updatedList);
  }

  //for DevTools to display context name
  ProfileContext.displayName = 'Profile Context';
  OfficesContext.displayName = 'Offices Context';

  return (
    <ProfileContext.Provider
      value={{
        profile,
        updateProfile,
        upsertProfileItem,
        removeProfileItem,
      }}
    >
      {isEmployer && (
        <OfficesContext.Provider
          value={{
            offices,
            primaryOffice,
            updateOffices,
            updatePrimaryOffice,
            upsertOffice,
            removeOffice,
            purchaseHistoryList,
            fetchReceipts,
          }}
        >
          <JobPostingContext>{props.children}</JobPostingContext>
        </OfficesContext.Provider>
      )}
      {isStaff && (
        <InvitationsContext>
          <ApplicationsContext>{props.children}</ApplicationsContext>
        </InvitationsContext>
      )}
    </ProfileContext.Provider>
  );
};

export default ProfileContextWrapper;
