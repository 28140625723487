import React, { useState, useEffect, useContext, useGlobal } from 'reactn';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { positionOptions, roleOptions } from '../../utils/displayHelper';
import { Link } from 'gatsby';
import * as routes from '../../routes';
import Hubspot from '../../agent-hubspot';
import { GlobalContext } from '../../context/GlobalContext';
import analytics, {
  GENERATE_LEAD_ACTION,
  ENGAGEMENT_CATEGORY,
  LEAD_LABEL,
  ASSET_DOWNLOAD_ACTION,
} from '../../analytics';

const LeadCaptureForm = (props: {
  formId: string;
  formName?:
    | 'jobseeker-toolkit'
    | 'hiring-toolkit'
    | 'newsletter'
    | 'contact-us';
  leadSource?: string;
  assetTitle?: string;
  userRole?: 'employer' | 'staff' | 'student' | 'other';
  redirectPath?: string;
  cta?: string;
  formFields: string[];
  handleSuccess?: (role?: 'employer' | 'staff') => {};
}) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const { urlParams, updateRedirectPath, updateLeadCaptureFields } =
    useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [displayRoleOther, setDisplayRoleOther] = useState(false);
  const [displayPosition, setDisplayPosition] = useState(
    props.userRole === 'staff'
  );
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

  useEffect(() => {
    if (!!props.redirectPath && !isAuthenticated) {
      updateRedirectPath(props.redirectPath);
    }
  }, []);

  useEffect(() => {
    if (formSubmitSuccess && !!document) {
      const form = document.getElementById('lead-form') as HTMLFormElement;
      form?.reset();
    }
  }, [formSubmitSuccess]);

  function submitForm(e) {
    e.preventDefault();
    setIsSubmitting(true);
    const {
      name,
      practiceName,
      leadEmail,
      phone,
      role,
      roleOther,
      position,
      zip,
    } = e.target.elements;
    if (!!props.assetTitle) {
      updateLeadCaptureFields({
        email: leadEmail.value,
        name: name ? name.value : undefined,
        phone: phone ? phone.value : undefined,
        resource: props.assetTitle,
      });
    }
    const leadRole = props.userRole ? props.userRole : role.value;
    const leadPosition = position ? position.value : '';
    Hubspot.submitHubspotForm({
      formName: props.formName,
      email: leadEmail.value,
      phone: phone ? phone.value : '',
      userRole: 'lead',
      name: name ? name.value : '',
      leadPracticeName: practiceName ? practiceName.value : '',
      positionCode: leadPosition,
      role: leadRole,
      roleOther: roleOther ? roleOther.value : '',
      leadZip: zip ? zip.value : '',
      leadSource: !!props.leadSource ? props.leadSource : undefined,
      assetTitle: !!props.assetTitle ? props.assetTitle : undefined,
      utmSource: urlParams.utm_source,
      utmMedium: urlParams.utm_medium,
      utmCampaign: urlParams.utm_campaign,
      utmContent: urlParams.utm_content,
    })
      .then((res) => {
        analytics.Events.trackEvent({
          actionType: GENERATE_LEAD_ACTION,
          category: ENGAGEMENT_CATEGORY,
          label: LEAD_LABEL,
          payload: {
            lead_type: leadRole,
            download_content: props.assetTitle,
            lead_jobtype: leadPosition,
            formId: props.formId,
          },
        });
        if (!!props.assetTitle) {
          analytics.Events.trackEvent({
            actionType: ASSET_DOWNLOAD_ACTION,
            category: ENGAGEMENT_CATEGORY,
            label: props.assetTitle,
            payload: {
              formId: props.formId,
            },
          });
        }
        updateLeadCaptureFields({
          name: name?.value ?? '',
          phone: phone?.value ?? '',
          email: leadEmail?.value ?? '',
          resource: props.assetTitle ?? '',
        });
        if (!!props.handleSuccess) {
          const submittedRole = props.userRole ? props.userRole : role.value;
          props.handleSuccess(submittedRole);
        }
        setIsSubmitting(false);
        setFormSubmitSuccess(true);
      })
      .catch((err) => {
        setIsSubmitting(false);
        setFormSubmitSuccess(false);
        setErrorMessage(
          'There was an error submitting the form. Please try again in 1 minute.'
        );
      });
    return false;
  }

  return (
    <>
      <Form
        className="mx-auto text-black w-100"
        id={props.formId}
        onSubmit={submitForm}
        style={{ width: '600px', maxWidth: '100%' }}
      >
        <fieldset disabled={isSubmitting}>
          <FormGroup floating>
            <Input
              name="name"
              type="text"
              required
              id="leadName"
              autoComplete="name"
            ></Input>
            <Label for="leadName">
              Name<span className="text-danger">*</span>
            </Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              name="leadEmail"
              type="email"
              id="leadEmail"
              required
            ></Input>
            <Label for="leadEmail">
              Email<span className="text-danger">*</span>
            </Label>
          </FormGroup>
          {!!props.formFields && props.formFields.length > 0 && (
            <>
              {props.formFields.includes('dp__lead_practice_name') && (
                <FormGroup floating>
                  <Input name="practiceName" type="text"></Input>
                  <Label for="practiceName">Practice Name</Label>
                </FormGroup>
              )}
              {props.formFields.includes('dp__lead_phone') && (
                <FormGroup floating>
                  <Input name="phone" type="tel"></Input>
                  <Label>Phone</Label>
                </FormGroup>
              )}
              {props.formFields.includes('dp__lead_zip') && (
                <FormGroup floating>
                  <Input name="zip" type="text"></Input>
                  <Label>Zip</Label>
                </FormGroup>
              )}
            </>
          )}
          {!props.userRole && (
            <FormGroup floating>
              <Input
                name="role"
                id="user-role"
                type="select"
                onChange={(e) => {
                  if (e.target.value === 'other') {
                    setDisplayRoleOther(true);
                  } else if (e.target.value === 'staff') {
                    setDisplayPosition(true);
                  } else {
                    setDisplayRoleOther(false);
                    setDisplayPosition(false);
                  }
                }}
              >
                <option value=""></option>
                {roleOptions.map((obj) => {
                  return (
                    <option value={obj.code} key={obj.code}>
                      {obj.label}
                    </option>
                  );
                })}
              </Input>
              <Label for="user-role">
                Role<span className="text-danger">*</span>
              </Label>
            </FormGroup>
          )}
          {displayPosition && (
            <FormGroup floating>
              <Input name="position" type="select">
                <option value=""></option>
                {positionOptions.map((obj) => {
                  return (
                    <option value={obj.code} key={obj.code}>
                      {obj.label}
                    </option>
                  );
                })}
              </Input>
              <Label>
                <strong>Position</strong>
              </Label>
            </FormGroup>
          )}
          {displayRoleOther && (
            <FormGroup floating>
              <Input name="roleOther" type="text"></Input>
              <Label>Other: Please Specify</Label>
            </FormGroup>
          )}
          {!!props.cta && (
            <Button color="primary" type="submit" className="w-100 mb-2">
              {props.cta}
            </Button>
          )}
          {!!props.redirectPath && (
            <p>
              Already have an account? <Link to={routes.toSignIn}>Log in</Link>
            </p>
          )}
        </fieldset>
        {!!formSubmitSuccess && (
          <div
            className={`alert alert-success my-3 alert-dismissible`}
            role="alert"
            id="newsletter-success-alert"
            color="success"
          >
            Thank you, please check your email!{' '}
            <Button
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setFormSubmitSuccess(false)}
            />
          </div>
        )}
        {!!errorMessage && (
          <div
            className={`alert alert-danger my-3`}
            role="alert"
            id="newsletter-error-alert"
            color="success"
          >
            {errorMessage}
          </div>
        )}
      </Form>
    </>
  );
};

export default LeadCaptureForm;
