import React, { useState, useEffect } from 'react';
import agent from '../agent';
import useInterval from '../hooks/useInterval';
import types from '../types/services-api';

export type ConversationsContextType = {
  conversationsList: types.UserConversationViewType[];
  updateConversation: (
    updatedConversation: types.UserConversationViewType
  ) => void;
  updateConversationsList: (
    additionalConversations: types.UserConversationViewType[]
  ) => void;
  resetConversationsList: (
    coversations: types.UserConversationViewType[]
  ) => void;
  loadMoreConversations: (callback?: () => void) => void;
  offsetInbox: number;
  isLoadingMoreConversations: boolean;
  unreadMessages: number;
  updateUnreadMessages: (messageCount: number) => void;
};

export const ConversationsContext =
  React.createContext<ConversationsContextType>({
    conversationsList: [],
    updateConversation: (updatedConversation) => {},
    updateConversationsList: (additionalConversations) => {},
    resetConversationsList: (coversations) => {},
    loadMoreConversations: (callback) => {},
    offsetInbox: 0,
    isLoadingMoreConversations: false,
    unreadMessages: 0,
    updateUnreadMessages: (messageCount) => {},
  });

const INITIAL_INTERVAL_MS = 1000 * 10; // 10 seconds
const MAX_INTERVAL_MS = 1000 * 60 * 120; // 120 minutes

const ConversationsContextWrapper = (props) => {
  const [conversations, setConversations] = useState<
    types.UserConversationViewType[]
  >([]);
  const [changesetCheckIntervalMs, setChangesetCheckIntervalMs] =
    useState(INITIAL_INTERVAL_MS);

  useEffect(() => {
    fetchConversations();
  }, []);

  function fetchConversations() {
    agent.Conversations.get({ limit: PAGE_LIMIT, offset: 0 })
      .then((res) => {
        updateConversationsList(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useInterval(() => {
    // console.log('use interval invoked ' + changesetCheckIntervalMs);
    if (changesetCheckIntervalMs) {
      agent.Conversations.getChangeset()
        .then((res) => {
          if (
            res.data.totalUnreadCount === unreadMessages &&
            changesetCheckIntervalMs < MAX_INTERVAL_MS
          ) {
            // changeset didn't change, increase the interval
            const newIntervalMs = Math.round(
              changesetCheckIntervalMs + changesetCheckIntervalMs * 0.5
            );
            setChangesetCheckIntervalMs(newIntervalMs);
          } else {
            updateUnreadMessages(res.data.totalUnreadCount);
          }
        })
        .catch((err) => {
          console.warn(err.message);
        });
    }
  }, changesetCheckIntervalMs);

  function updateConversationsList(updatedList) {
    setConversations([...conversations, ...updatedList]);
  }

  function resetConversationsList(conversations) {
    setConversations([]);
  }

  function updateConversation(updatedItem: types.UserConversationViewType) {
    if (conversations && conversations.length > 0) {
      const updatedConversations = conversations.map((c) => {
        return Number(c.appId) === Number(updatedItem.appId)
          ? {
              ...c,
              ...updatedItem,
            }
          : c;
      });
      setConversations(updatedConversations);
    }
  }

  const PAGE_LIMIT = 30;
  const [offsetInbox, setOffsetInbox] = useState(PAGE_LIMIT);
  const [isLoadingMoreConversations, setIsLoadingMoreConversations] =
    useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  function loadMoreConversations(callback) {
    setIsLoadingMoreConversations(true);
    const requestBody = {
      limit: PAGE_LIMIT,
      offset: offsetInbox,
    };
    agent.Conversations.get(requestBody)
      .then((res) => {
        setIsLoadingMoreConversations(false);
        updateConversationsList(res.data);
        setOffsetInbox((prev) => prev + PAGE_LIMIT);
        return res;
      })
      .then((res) => {
        if (callback) {
          callback(res.data[0]);
        }
      });
  }

  function updateUnreadMessages(messageCount: number) {
    setUnreadMessages(messageCount);
    setChangesetCheckIntervalMs(INITIAL_INTERVAL_MS);
  }

  //for DevTools to display context name
  ConversationsContext.displayName = 'Conversations Context';

  return (
    <ConversationsContext.Provider
      value={{
        conversationsList: conversations,
        updateConversation,
        updateConversationsList,
        resetConversationsList,
        loadMoreConversations,
        offsetInbox,
        isLoadingMoreConversations,
        unreadMessages,
        updateUnreadMessages,
      }}
    >
      {props.children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsContextWrapper;
