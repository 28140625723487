import React, { useContext } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link } from 'gatsby';
import { toSignIn, toGetStarted } from '../../routes';
import NavSlideover from './NavSlideover';
import Navbar from './Navbar';
import Footer from './Footer';
import { NavCategoriesType } from './Layout';
import { PropsWithChildren } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

const UnauthenticatedLayout = (
  props: PropsWithChildren<{
    displayLeadForm: boolean;
    navCategories: NavCategoriesType;
  }>
) => {
  const { bannerHeight, setBannerHeight } = useContext(GlobalContext);

  function updateLayout(height: number) {
    setBannerHeight(height);
  }

  return (
    <Container fluid className="bg-white px-0">
      <Row
        className="g-0 border-bottom border-light w-100"
        style={{ position: 'sticky', top: 0, zIndex: 1000 }}
      >
        <Col className="px-0">
          <Navbar
            navCategories={props.navCategories}
            updateLayout={updateLayout}
            bannerHeight={bannerHeight}
          >
            {/* DESKTOP ACTIONS */}
            <div className="d-none d-xl-flex">
              <Button
                color="link"
                className="text-black fw-light me-2"
                tag={Link}
                to={toSignIn}
              >
                Sign In
              </Button>
              <Button color="primary" outline tag={Link} to={toGetStarted}>
                Create Account
              </Button>
            </div>
            {/* MOBILE ACTIONS */}
            <NavSlideover navCategories={props.navCategories} />
          </Navbar>
        </Col>
      </Row>
      <Row
        className={'g-0 position-relative not-authenticated flex-grow-1'}
        style={{ overflowX: 'hidden' }}
        id="main-content"
      >
        <Col
          className="px-0"
          style={{
            height: `calc(100dvh - 85px${bannerHeight > 0 ? ` - ${bannerHeight}px)` : ')'}`,
          }}
        >
          <div
            style={{
              minHeight: '100%',
            }}
          >
            {props.children}
          </div>
          <Footer displayLeadForm={props.displayLeadForm} />
        </Col>
      </Row>
    </Container>
  );
};

export default UnauthenticatedLayout;
