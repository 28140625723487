import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Input, Form, InputGroup } from 'reactstrap';

const SearchBox = connectSearchBox(
  ({
    className,
    placeholder,
    autoFocus,
    refine,
    currentRefinement,
    onSubmit,
  }) => {
    return (
      <>
        <Form
          className={className}
          onSubmit={(e) => {
            if (onSubmit) {
              console.log('submitting ' + currentRefinement);
              onSubmit(currentRefinement);
            }
            e.preventDefault();
          }}
        >
          <InputGroup className="position-relative">
            <Input
              onChange={(e) => {
                refine(e.target.value);
              }}
              autoFocus={autoFocus}
              value={currentRefinement}
              name="search-input"
              placeholder={placeholder}
              type="search"
            />
          </InputGroup>
        </Form>
      </>
    );
  }
);

export default SearchBox;
