import algoliasearch from 'algoliasearch/lite';
import React, { useState, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import SearchBox from './searchBox';
import SearchResult from './searchResult';
import classNames from 'classnames';
import { navigate } from '@gatsbyjs/reach-router';
import { FaSearch } from 'react-icons/fa';
import {
  GATSBY_ALGOLIA_APP_ID,
  GATSBY_ALGOLIA_SEARCH_KEY,
  searchIndices,
} from './searchConstants';

import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const SearchModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const algoliaClient = useMemo(
    () => algoliasearch(GATSBY_ALGOLIA_APP_ID, GATSBY_ALGOLIA_SEARCH_KEY),
    []
  );

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => params.query.trim().length < 3)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  async function goToLink(link) {
    toggleModal();
    navigate(link);
  }

  return (
    <>
      <Button
        type="button"
        onClick={toggleModal}
        color="transparent"
        className={
          'ms-auto ms-sm-0 me-0 text-black d-flex justify-content-end align-items-center col-auto'
        }
        aria-label="search dentalpost content"
      >
        <FaSearch className="dp-font-lg" />
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        contentClassName="dp-search-modal"
        autoFocus={false}
        returnFocusAfterClose={false}
      >
        <ModalHeader toggle={props.onCancel}>Search DentalPost</ModalHeader>
        <ModalBody>
          <div>
            <InstantSearch
              searchClient={searchClient}
              indexName={searchIndices[0].name}
              onSearchStateChange={({ query }) => {
                setQuery(query);
              }}
              searchState={{
                query: query,
              }}
            >
              <SearchBox
                className="dp-search-input"
                placeholder="Search jobs, blog articles, events, etc"
                autoFocus={true}
              />
              <SearchResult
                indices={searchIndices}
                goToLink={goToLink}
                className={classNames('dp-search-results', {
                  'd-none': !(query && query.trim().length > 2),
                })}
              />
            </InstantSearch>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SearchModal;
