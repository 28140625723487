// Algolia constants
// May be overridden by .env.algolia
//

module.exports.GATSBY_ALGOLIA_APP_ID =
  process.env.GATSBY_ALGOLIA_APP_ID || 'SF1OTRAZMO';

module.exports.GATSBY_ALGOLIA_SEARCH_KEY =
  process.env.GATSBY_ALGOLIA_SEARCH_KEY || '349136e0d14ae4bef62b8af517dfe4ce';

module.exports.ALGOLIA_API_KEY = process.env.ALGOLIA_API_KEY;

module.exports.searchIndices = [
  { name: `JobDirs`, title: `Job Directories` },
  { name: `Jobs`, title: `Job Postings` },
  { name: `Pages`, title: `Site Pages` },
  { name: `Authors`, title: `Authors` },
  { name: `Posts`, title: `Posts` },
  { name: `Events`, title: `Events` },
  { name: `Classifieds`, title: `Classifieds` },
];

module.exports.jobSearchIndices = [
  { name: `JobDirs`, title: `Job Directories` },
  { name: `Jobs`, title: `Job Postings` },
];
