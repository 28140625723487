import React, { useState, useGlobal, useEffect, useRef } from 'reactn';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FaArrowRight, FaBullhorn } from 'react-icons/fa';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import OutboundLink from '../common/OutboundLink';

const BUILD_ID = process.env.GATSBY_BUILD_ID || 'dev';

const BannerAnnouncement = (props: {
  updateLayout: (height: number) => void;
}) => {
  const [isAuthenticated] = useGlobal<{ isAuthenticated: boolean }>(
    'isAuthenticated'
  );
  const [isEmployer] = useGlobal<{ isEmployer: boolean }>('isEmployer');
  const [isStaff] = useGlobal<{ isStaff: boolean }>('isStaff');
  const data = useStaticQuery<{
    allContentfulBannerAnnouncement: {
      edges: {
        node: BannerAnnouncementType;
      }[];
    };
  }>(contentQuery);
  const [bannerContent, setBannerContent] = useState<
    BannerAnnouncementType | undefined
  >(
    data.allContentfulBannerAnnouncement.edges.filter(
      (edge) =>
        edge.node.buildEnvironments.includes(BUILD_ID) &&
        edge.node.userRole === 'unauthenticated'
    )[0]?.node
  );

  const announcementBannerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      !!announcementBannerRef.current &&
      announcementBannerRef.current?.offsetHeight > 0
    ) {
      props.updateLayout(announcementBannerRef.current?.offsetHeight);
    }
  }, [announcementBannerRef.current?.offsetHeight]);

  // const [viewedAnnoucementIds, setViewedAnnouncementId] = useLocalStorageState<
  //   string[]
  // >('viewedAnnoucementIds', { defaultValue: [] });

  useEffect(() => {
    if (isAuthenticated) {
      const content = data.allContentfulBannerAnnouncement.edges.filter(
        (edge) =>
          edge.node.buildEnvironments.includes(BUILD_ID) &&
          (isEmployer
            ? edge.node.userRole === 'employer'
            : isStaff
              ? edge.node.userRole === 'staff'
              : undefined)
      );
      const banner = content.length > 0 ? content[0]?.node : undefined;
      setBannerContent(banner);
    }
  }, [isAuthenticated]);

  // function toggleBanner(bannerId: string) {
  //   setViewedAnnouncementId([...viewedAnnoucementIds, bannerId]);
  // }

  const image = () => {
    if (!bannerContent) {
      return null;
    } else {
      const image = getImage(bannerContent.icon?.gatsbyImageData);
      if (image) {
        return (
          <div style={{ width: '35px', height: '35px' }}>
            <GatsbyImage
              loading="lazy"
              image={image}
              alt={bannerContent.icon.title}
            />
          </div>
        );
      } else {
        return <FaBullhorn className="fa-2x" />;
      }
    }
  };

  return (
    <div
      id="announcement-banner"
      ref={announcementBannerRef}
      style={{
        backgroundColor: bannerContent?.backgroundColor,
        height: !!bannerContent ? 'auto' : 0,
        overflow: !!bannerContent ? undefined : 'hidden',
      }}
    >
      <div className="d-flex flex-wrap justify-content-center align-items-center py-2">
        <div
          className={`text-dark col-auto me-3 mb-0 d-flex align-items-center`}
        >
          {image()}
        </div>
        <h5 className="mb-0 col-auto text-center" style={{ maxWidth: '100%' }}>
          {bannerContent?.heading}
        </h5>
        {!!bannerContent &&
          bannerContent.ctaLink.includes('dentalpost.net') && (
            <Link
              to={bannerContent.ctaLink.replace(/.*\/\/[^\/]*/, '')}
              className="col-auto mx-5"
            >
              {bannerContent?.ctaText} <FaArrowRight />
            </Link>
          )}
        {!!bannerContent &&
          !bannerContent.ctaLink.includes('dentalpost.net') && (
            <OutboundLink
              href={bannerContent.ctaLink}
              className="col-auto mx-5"
            >
              {bannerContent?.ctaText} <FaArrowRight />
            </OutboundLink>
          )}
      </div>
    </div>
  );
};

export default BannerAnnouncement;

const contentQuery = graphql`
  query {
    allContentfulBannerAnnouncement {
      edges {
        node {
          backgroundColor
          ctaText
          id
          heading
          userRole
          ctaLink
          buildEnvironments
          icon {
            gatsbyImageData(width: 100, height: 100)
            title
          }
        }
      }
    }
  }
`;

export type BannerAnnouncementType = {
  backgroundColor: string;
  ctaText: string;
  id: string;
  heading: string;
  userRole: string;
  ctaLink: string;
  buildEnvironments: string;
  icon: {
    gatsbyImageData: IGatsbyImageData;
    title: string;
  };
};
